import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';
import ReportsRoutes from './reports/Routes';
import ActionsRoutes from './actions/Routes';
import TemplatesRoutes from './templates/Routes';

const Personas = lazy(() => import('./personas'));

function Routes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/lia/personas'}
        title="LIA Personas"
        component={Personas}
      />
      <Route path="/lighthouse/lia/reports*">
        <ReportsRoutes />
      </Route>

      <Route path="/lighthouse/lia/actions*">
        <ActionsRoutes />
      </Route>

      <Route path="/lighthouse/lia/templates*">
        <TemplatesRoutes />
      </Route>
    </Switch>
  );
}

export default Routes;
