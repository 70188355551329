import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));

function Routes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`/settings/cloud-accounts`}
        title="Cloud Accounts"
        component={Home}
      />
    </Switch>
  );
}

export default Routes;
