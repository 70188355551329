import React, { useMemo } from 'react';
import lighthouseIcon from '@/assets/images/lighthouse_icon.png';
import autofixIcon from '@/assets/images/autofix_icon.png';
import spaceIcon from '@/assets/images/space_icon.png';
import ccaIcon from '@/assets/images/cca_icon.png';
import spotIcon from '@/assets/images/spot_icon.png';
import spManagerIcon from '@/assets/images/sp_manager_icon.png';
import { Button } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { MenuOutlined } from '@ant-design/icons';
import { usePlatform } from '@/context/PlatformContext';
import clsx from 'clsx';
import useActiveFeatureFlagsAlias from '@/hooks/useActiveFeatureFlagsAlias';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 75,
    maxWidth: 75,
    width: 75,
    height: '100vh',
    background: '#f6f6f6',
    borderRight: '1px solid lightgrey',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    '& .name': {
      marginTop: 6,
      textAlign: 'center',
      fontSize: 11,
      fontWeight: 500,
      color: '#999999',
    },
    '&:not(.active):hover': {
      '& img': {
        filter: 'grayscale(0%)',
      },
    },
    '& img': {
      filter: 'grayscale(100%)',
      transition: 'filter 300ms',
    },
    '&.disabled': {
      cursor: 'default',
      filter: 'opacity(0.3)',
    },
    '& .bar': {
      marginTop: 6,
      width: 0,
      height: 3,
      background: '#338fcc',
      borderRadius: 5,
    },
    '&.active': {
      '& .name': {
        color: '#333333',
      },
      '& img': {
        filter: 'grayscale(0%)',
      },
      '& .bar': {
        width: 32,
      },
    },
    '&.bottom': {
      marginTop: 'auto',
    },
  },
}));

function ProductsMenu({ product }) {
  const classes = useStyles();
  const history = useHistory();
  const { toggleSiderMenu } = useAuth();
  const { context, business } = usePlatform();
  const { featureFlags } = useActiveFeatureFlagsAlias({ business });

  let providersCca = ['aws', 'azure'];
  if (!featureFlags.has('checkers-gcp')) {
    providersCca.push('gcp');
  }

  const products = useMemo(
    () =>
      [
        {
          key: 'lighthouse',
          name: 'Lighthouse',
          icon: lighthouseIcon,
        },
        {
          key: 'cca',
          name: 'CCA',
          icon: ccaIcon,
          providers: providersCca,
        },
        {
          key: 'autofix',
          name: 'Autofix',
          icon: autofixIcon,
          providers: ['aws', 'azure'],
        },
        {
          key: 'spot',
          name: 'Spot',
          icon: spotIcon,
          providers: ['aws'],
        },
        {
          key: 'sp-manager',
          name: 'SP Manager',
          icon: spManagerIcon,
          providers: ['aws'],
          hide: !business?.global,
        },
        {
          key: 'space',
          name: 'Space',
          icon: spaceIcon,
        },
      ].filter(
        ({ hide, providers }) =>
          !hide && (!providers || providers.includes(context?.provider))
      ),
    [context]
  );

  const handleGoTo = (location) => () => {
    history.push(location);
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 64,
        }}
      >
        <Button
          type="text"
          onClick={() => toggleSiderMenu()}
          style={{ padding: 0, margin: 0 }}
        >
          <MenuOutlined style={{ fontSize: 17 }} />
        </Button>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: 'calc(100% - 64px)',
          gap: 18,
          paddingBottom: 8,
          paddingTop: 8,
        }}
      >
        {products.map((item) => (
          <div
            key={item.key}
            className={clsx(classes.button, {
              active: product === item.key,
              bottom: item.bottom,
            })}
            onClick={handleGoTo(`/${item.key}`)}
          >
            <img src={item.icon} alt={`Logo ${item.name}`} height={30} />
            <div className="name">{item.name}</div>
            <div className="bar" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductsMenu;
