import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Modal, Form, Button, Select, Typography, Space, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { usePlatform } from '@/context/PlatformContext';
import useFieldsList from '@/hooks/useFieldsList';
import { NumberOutlined } from '@ant-design/icons';
import LoadingSpinner from '@/components/LoadingSpinner';
import withModal from '@/components/withModal';

import useMutationUpdateBudgetSettings from '@/mutations/useMutationUpdateBudgetSettings';
import useMutationDeleteBudgetSettings from '@/mutations/useMutationDeleteBudgetSettings';
import useFetchBudgetSettings from '@/queries/useFetchBudgetSettings';
import useFetchDataConnectors from '@/queries/useFetchDataConnectors';
import useFetchContexts from '@/queries/useFetchContexts';
import { getAllDataConnectorKinds } from '@/common/dataConnectors';

function BudgetSettingsModal({ onClose }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { billing } = usePlatform();
  const [settings, setSettings] = useState([]);
  const [selectedBilling, setSelectedBilling] = useState(billing?.id);

  const { isLoading, fieldsList } = useFieldsList({
    dataTypes: ['number'],
    dataConnectorId: selectedBilling,
    enabled: !!selectedBilling,
  });

  const fetchContexts = useFetchContexts();
  const fetchDataConnectors = useFetchDataConnectors({
    pageSize: 9999,
    kind: getAllDataConnectorKinds({ onlyBilling: true }).join(','),
    status: true,
  });
  const fetchSettings = useFetchBudgetSettings({});
  const mutateSettings = useMutationUpdateBudgetSettings();
  const deleteSettings = useMutationDeleteBudgetSettings();

  useEffect(() => {
    if (fetchSettings.isSuccess) {
      setSettings(fetchSettings.data);
    }
  }, [fetchSettings]);

  const contexts = useMemo(() => {
    return fetchContexts.isSuccess
      ? fetchContexts.data.contexts.filter((context) => !!context.provider)
      : [];
  }, [fetchContexts]);

  const billingConnectors = useMemo(() => {
    return fetchDataConnectors.isSuccess
      ? fetchDataConnectors.data.data_connectors
      : [];
  }, [fetchDataConnectors]);

  const getSettings = useCallback(
    (provider) =>
      settings
        .filter((setting) => setting.status !== 'deleted')
        .find((setting) => setting.provider === provider),
    [settings]
  );

  const handleClear = (provider) => () => {
    setSettings((prev) => {
      const [setting, index] = prev.reduce(
        (acc, setting, index) =>
          setting.provider === provider ? [setting, index] : acc,
        [null, -1]
      );

      if (!setting) return prev;

      prev[index] = {
        ...setting,
        data: null,
        status: 'deleted',
      };
      return [...prev];
    });
  };

  const handleSelect = (provider) => (name) => {
    const field = fieldsList.find((item) => item.name === name);

    if (!field) {
      throw new Error(`Field not found in fields list by the name: ${name}`);
    }

    setSettings((prev) => {
      const settingIndex = prev.findIndex(
        (setting) => setting.provider === provider
      );
      const isCustom = field.kind === 'custom';
      const key = isCustom
        ? field.calculated_field?.default_version?.key
        : `"${field.name}"`;
      const newSetting = {
        data: {
          fieldId: field.id,
          name: field.name,
          key,
          custom: isCustom,
        },
        provider,
      };

      if (settingIndex === -1) {
        prev.push({ ...newSetting, status: 'created' });
        return [...prev];
      } else {
        prev[settingIndex] = { ...newSetting, status: 'edited' };
        return [...prev];
      }
    });
  };

  const handleSelectBilling = (id) => {
    setSelectedBilling(id);
  };

  const handleSave = async () => {
    for await (const setting of settings) {
      if (setting.status === 'created' || setting.status === 'edited') {
        await mutateSettings.mutateAsync({
          provider: setting.provider,
          data: setting.data,
        });
      } else if (setting.status === 'deleted' && setting.id) {
        await deleteSettings.mutateAsync(setting.id);
      }
    }
    onClose();
  };

  return (
    <Modal
      open
      onCancel={onClose}
      title={t('common.settings')}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('common.close')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          loading={mutateSettings.isLoading}
        >
          {t('common.save')}
        </Button>,
      ]}
    >
      {fetchSettings.isLoading || fetchSettings.isFetching ? (
        <LoadingSpinner height={200} />
      ) : (
        <Form
          form={form}
          id="budget-settings"
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label={'Billing'}
            tooltip={t('common.chargeback.billing.connector.tooltip')}
          >
            <Select
              showSearch
              loading={isLoading}
              value={selectedBilling}
              onSelect={handleSelectBilling}
            >
              {billingConnectors.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Divider />

          {contexts.map((context) => (
            <Form.Item
              key={`context-${context.id}`}
              label={t('common.chargeback.field', {
                context: context.name,
              })}
            >
              <Select
                showSearch
                loading={isLoading}
                allowClear
                value={getSettings(context.provider)?.data?.name}
                onClear={handleClear(context.provider)}
                onSelect={handleSelect(context.provider)}
              >
                {fieldsList.map(({ name }) => (
                  <Select.Option key={name} value={name}>
                    <Space>
                      <NumberOutlined className="icon" />
                      <Typography.Text type="primary">{name}</Typography.Text>
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ))}
        </Form>
      )}
    </Modal>
  );
}

export default withModal({
  key: 'BUDGET_SETTINGS',
  component: BudgetSettingsModal,
});
