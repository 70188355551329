import { useQuery } from 'react-query';
import { apiWithoutBaseUrl } from '@/services/lighthouse';

function useFetchFeatureFlags({ enabled } = { enabled: true }) {
  return useQuery(
    ['feature-flags'],
    () => apiWithoutBaseUrl.get(`/feature_flags`).then((res) => res.data),
    {
      enabled,
      retry: 0,
    }
  );
}

export default useFetchFeatureFlags;
