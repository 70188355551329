import { useMutation } from 'react-query';
import { message } from 'antd';
import { apiWithoutBaseUrl } from '@/services/lighthouse';
import { useTranslation } from 'react-i18next';

export default function useMutationFlushCache() {
  const { t } = useTranslation();

  return useMutation(
    ({ businessId, kind }) =>
      apiWithoutBaseUrl.post('/flush/cache', {
        business_id: businessId,
        kind,
      }),
    {
      onMutate() {
        message.loading({
          content: t('datacache.message.clearing'),
          key: 'flush-cache',
        });
      },
      onSuccess() {
        message.success({
          content: t('datacache.message.clearing.success'),
          key: 'flush-cache',
          duration: 8,
        });
      },
      onError(error) {
        message.error({
          content: `${t('datacache.message.clearing.error')} ${error}`,
          key: 'flush-cache',
          duration: 6,
        });
      },
    }
  );
}
