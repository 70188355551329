import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';
import { useTranslation } from 'react-i18next';

const Reports = lazy(() => import('./home'));
const ReportsHistory = lazy(() => import('./history'));
const BI = lazy(() => import('../workspaces/bi'));

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/reports'}
        title={t('menu.sidebar.reports')}
        component={Reports}
      />
      <PrivateRoute
        exact
        path={'/lighthouse/reports/:id/history'}
        title={t('menu.sidebar.reports')}
        component={ReportsHistory}
      />
      <PrivateRoute
        exact
        path={[
          '/lighthouse/reports/new',
          '/lighthouse/reports/:report/view/:view',
        ]}
        title="BI"
        component={BI}
        renderLayout={false}
      />
    </Switch>
  );
}

export default Routes;
