import { lazy } from 'react';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import { getAllProvidersKeys } from '@/common/cloudProviders';

const routes = [
  {
    path: '/lighthouse/budget/receivers/import-transactions',
    exact: true,
    title: 'lighthouse:receivers.import',
    component: lazy(() => import('./pages/importTransactions')),
    providers: getAllProvidersKeys(),
  },
  {
    path: '/lighthouse/budget/receivers/settings',
    exact: true,
    title: 'router.receivers.settings.title',
    component: lazy(() => import('./pages/settings')),
    providers: getAllProvidersKeys(),
  },
  {
    path: '/lighthouse/budget/receivers',
    exact: true,
    title: 'router.receivers.title',
    component: lazy(() => import('./pages/home')),
    providers: getAllProvidersKeys(),
  },
];

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      {routes.map(({ title, ...rest }) => (
        <PrivateRoute key={rest.path} title={t(title)} {...rest} />
      ))}
    </Switch>
  );
}

export default Routes;
