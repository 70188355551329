import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));

function Routes() {
  return (
    <>
      <PrivateRoute exact path={`/spot/logs`} title="Logs" component={Home} />
    </>
  );
}

export default Routes;
