import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Actions = lazy(() => import('./actions'));

function Routes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`/settings/data-connectors`}
        title="Data Connectors"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/settings/data-connectors/:id/actions`}
        title="Data Connectors Actions"
        component={Actions}
      />
    </Switch>
  );
}

export default Routes;
