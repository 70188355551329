import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Purchases = lazy(() => import('./purchases'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/sp-manager/outlet`}
        title="Outlet"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/sp-manager/outlet/purchases`}
        title="Purchases"
        component={Purchases}
      />
    </>
  );
}

export default Routes;
