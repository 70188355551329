import { Suspense } from 'react';
import { Redirect, Route } from 'react-router';
import { useAuth } from '@/context/AuthContext';
import setDocTitle from '@/common/setDocTitle';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../LoadingSpinner';

export default function PublicRoute({ title, component: Component, ...rest }) {
  const { authenticated } = useAuth();
  const { t } = useTranslation();

  setDocTitle(t(title));

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        authenticated() ? (
          <Redirect
            to={{
              pathname: `/setup/select-orgs`,
              state: { from: routeProps.location },
            }}
          />
        ) : (
          <Suspense fallback={<LoadingSpinner />}>
            <Component {...routeProps} />
          </Suspense>
        )
      }
    />
  );
}
