import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Upsert = lazy(() => import('./upsert'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/lighthouse/lia/templates`}
        title="LIA Templates"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/lighthouse/lia/templates/create`}
        title="LIA Templates Create"
        component={Upsert}
      />
      <PrivateRoute
        exact
        path={`/lighthouse/lia/templates/edit/:id`}
        title="LIA Templates Edit"
        component={Upsert}
      />
    </>
  );
}

export default Routes;
