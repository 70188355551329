import { useModal } from '@/context/ModalContext';

function withModal({ component: Modal, key }) {
  return () => {
    const { getModal, getModalProps, closeModal } = useModal();
    const props = getModalProps(key);

    const handleClose = () => {
      closeModal(key);
    };

    if (!getModal(key)) {
      return null;
    }

    return <Modal onClose={handleClose} {...props} />;
  };
}

export default withModal;
