import { Result } from 'antd';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 'none',
    height: '100vh',
  },
}));

export default function Result403({ onClick }) {
  const classes = useStyles();

  return (
    <Result
      className={classes.root}
      status="403"
      title="Permissão negada"
      subTitle="Desculpe, mas você não tem permissão para realizar esta ação. Por favor entre em contato com o administrador!"
    />
  );
}
