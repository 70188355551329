import { useTranslation } from 'react-i18next';
import mapFromObject from '@/common/mapFromObject';

const languages = mapFromObject({
  'es-ES': {
    day: 'DD/MM/YYYY',
    month: 'MM/YYYY',
    competency: 'MMMM YYYY',
    shortCompetency: 'MMM YYYY',
    dayHour: 'DD/MM/YYYY, HH:mm A',
    hour: 'HHA',
  },
  'pt-BR': {
    day: 'DD/MM/YYYY',
    month: 'MM/YYYY',
    competency: 'MMMM YYYY',
    shortCompetency: 'MMM YYYY',
    dayHour: 'DD/MM/YYYY [às] HH:mm',
    hour: 'HH',
  },
  'en-US': {
    day: 'MM-DD-YYYY',
    month: 'MM-YYYY',
    competency: 'MMMM YYYY',
    shortCompetency: 'MMM YYYY',
    dayHour: 'MM-DD-YYYY, hh:mm A',
    hour: 'HHA',
  },
});

function useDateFormat(type) {
  const { i18n } = useTranslation();

  return languages.get(i18n.resolvedLanguage)[type] ?? 'YYYY-MM-DD';
}

export default useDateFormat;
