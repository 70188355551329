import React from 'react';
import { Modal, Typography, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/context/ModalContext';
import useMutationFlushCache from '@/mutations/useMutationFlushCache';
import { usePlatform } from '@/context/PlatformContext';
import { useAuth } from '@/context/AuthContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: '20px',
    paddingTop: '20px',
    borderTop: '1px solid #cccccc',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function FlushCacheModal({ onClose }) {
  const { t } = useTranslation();
  const { isEngineeringTeam } = useAuth();
  const classes = useStyles();

  const mutationFlushCache = useMutationFlushCache();
  const { business } = usePlatform();

  const handleFlushCacheQuery = () => {
    mutationFlushCache.mutate({ businessId: business.id, kind: 'query' });
    onClose();
  };

  const handleFlushCacheAll = () => {
    mutationFlushCache.mutate({ kind: 'all' });
    onClose();
  };

  return (
    <Modal
      title={t('user.clear.cache.confirm.title')}
      visible
      footer={null}
      width={600}
      onCancel={onClose}
    >
      <Typography>
        {t('user.clear.cache.confirm.description')}{' '}
        <strong>{business.name}</strong>.{' '}
        {t('user.clear.cache.confirm.description2')}
      </Typography>
      <Space className={classes.buttons}>
        {isEngineeringTeam && (
          <Button
            key="clean-cache-all"
            type="secondary"
            onClick={handleFlushCacheAll}
          >
            {t('user.clear.cache.all.business')}
          </Button>
        )}
        <Button key="close" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button
          key="clean-cache-query"
          htmlType="button"
          type="primary"
          onClick={handleFlushCacheQuery}
        >
          Sim, limpar o cache
        </Button>
      </Space>
    </Modal>
  );
}

export default function ModalWrapper() {
  const modalName = 'FLUSH_CACHE_MODAL';
  const { getModal, getModalProps, closeModal } = useModal();
  const props = getModalProps(modalName);

  const handleClose = () => {
    closeModal(modalName);
  };

  if (!getModal(modalName)) {
    return null;
  }

  return <FlushCacheModal onClose={handleClose} {...props} />;
}
