import { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SiderMenu from './components/SiderMenu';
import Header from './components/Header';
import ProductsMenu from './components/ProductsMenu';
import { Layout } from 'antd';
import { useAuth } from '@/context/AuthContext';
import { useLocation } from 'react-router-dom';
import Result403 from '@/components/Result403';
import { useHistory } from 'react-router-dom';
import { usePlatform } from '@/context/PlatformContext';

const CLOSED_WIDTH = 80;
const OPEN_WIDTH = 250;

const useStyles = makeStyles((theme) => ({
  sider: {
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: '100vh',
    background: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    transition: 'none',
  },
  main: {
    minHeight: '100vh',
    background: theme.palette.background.default,
    transition: 'none',
  },
}));

export default function MainLayout({
  children,
  showSideMenu,
  showSelectOptions,
  showProductsMenu,
  showUserOptionsMenu,
}) {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const { business } = usePlatform();
  const { collapsed } = useAuth();
  const [isError403, setIsError403] = useState(false);

  const siderWidth = useMemo(() => {
    return collapsed ? CLOSED_WIDTH : OPEN_WIDTH;
  }, [collapsed]);

  const handlerError403 = () => {
    const error = new URLSearchParams(window.location.search).get('error');
    setIsError403(error === '403');
  };

  useEffect(() => {
    window.addEventListener('error403', handlerError403);
    return () => {
      window.removeEventListener('error403', handlerError403);
    };
  }, []);

  useEffect(() => {
    if (!business) {
      if (location.pathname !== '/setup/select-orgs') {
        history.push('/setup/select-orgs');
      }
    }
  }, [business]);

  const product = useMemo(() => {
    return location.pathname.split('/').filter((val) => val !== '')[0];
  }, [location.pathname]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {showProductsMenu && <ProductsMenu product={product} />}

      <div style={{ width: '100%' }}>
        <Layout>
          <Header
            product={product}
            showSelectOptions={showSelectOptions}
            showUserOptionsMenu={showUserOptionsMenu}
          />

          <Layout>
            {showSideMenu && (
              <Layout.Sider
                width={siderWidth}
                className={classes.sider}
                collapsible
                collapsed={collapsed}
                trigger={null}
              >
                <SiderMenu product={product} />
              </Layout.Sider>
            )}
            <Layout className={classes.main}>
              <Layout.Content>
                {isError403 ? <Result403 /> : children}
              </Layout.Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    </div>
  );
}
