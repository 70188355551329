import React, { lazy, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import { getAllProvidersKeys } from '@/common/cloudProviders';

function Routes() {
  const { t } = useTranslation();

  const allProviders = useMemo(() => getAllProvidersKeys(), []);

  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/timeline'}
        title={t('common.timeline')}
        component={lazy(() => import('./home'))}
        providers={allProviders}
      />
    </Switch>
  );
}

export default Routes;
