import React from 'react';
import { Button, Dropdown } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    'border-radius': 16,
  },
}));

const LanguageSelect = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const languagesMap = new Map([
    ['pt-BR', 'Português'],
    ['en-US', 'English'],
    ['es-ES', 'Español'],
  ]);

  const items = [...languagesMap].map(([key, label]) => ({ key, label }));

  const language = languagesMap.get(String(i18n.resolvedLanguage));

  const handleMenuClick = ({ key }) => {
    i18n.changeLanguage(key);
  };

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }} trigger="click">
      <Button className={classes.button}>{language}</Button>
    </Dropdown>
  );
};
export default LanguageSelect;
