import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/spot/settings`}
        title="Settings"
        component={Home}
      />
    </>
  );
}

export default Routes;
