import React from 'react';
import { Modal, Form, Input, Upload, Button, message, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import useMutationCreateIssue from '@/mutations/useMutationCreateIssue';
import { upperFirst } from '@/common/strFuncs';
import { useTranslation } from 'react-i18next';

const errorIssue = process.env.REACT_APP_JIRA_ERROR_ISSUE_TYPE_ID;
const improvimentIssue = process.env.REACT_APP_JIRA_IMPROVIMENT_ISSUE_TYPE_ID;
const highPriorityId = process.env.REACT_APP_JIRA_HIGH_PRIORITY_ID;
const mediumPriorityId = process.env.REACT_APP_JIRA_MEDIUM_PRIORITY_ID;

export default function CreateIssueModal({ onClose, visible }) {
  const [form] = Form.useForm();
  const issueMutation = useMutationCreateIssue();
  const { t } = useTranslation();

  const RadioOptions = [
    { label: t('issue.report.error'), value: errorIssue },
    { label: t('issue.report.suggest'), value: improvimentIssue },
  ];

  const validateFileType = (file) => {
    if (file.type !== 'image/png') {
      message.error(`${file.name} ${t('issue.report.not.format.png')}`);
      return Upload.LIST_IGNORE;
    } else return false;
  };

  const uploadFile = (e) => {
    if (Array.isArray(e)) return e;
    else return e?.fileList;
  };

  function handleSave() {
    form.validateFields().then(async (values) => {
      values.priorityId =
        values.issueTypeId === errorIssue ? highPriorityId : mediumPriorityId;

      const data = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (key !== 'images') {
          data.append(key, upperFirst(value));
        } else {
          value?.forEach((image) => data.append('images', image.originFileObj));
        }
      });

      try {
        await issueMutation.mutate(data);
        handleClose();
      } catch (error) {
        console.error(error);
      }
    });
  }

  function handleClose() {
    form.resetFields();
    onClose();
  }

  return (
    <Modal
      title={t('issue.report.problem.or.suggest')}
      open={visible}
      width={680}
      onOk={handleSave}
      onCancel={handleClose}
      okText={t('common.send')}
      cancelText={t('common.cancel')}
      closable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSave}
        requiredMark="optional"
        initialValues={{ issueTypeId: errorIssue }}
      >
        <Form.Item
          name="issueTypeId"
          label={t('issue.report.form.type.label')}
          rules={[
            {
              required: true,
              message: t('issue.report.form.type.required'),
            },
          ]}
        >
          <Radio.Group
            options={RadioOptions}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>

        <Form.Item
          name="summary"
          label={t('issue.report.form.title.label')}
          rules={[
            {
              required: true,
              message: t('issue.report.form.title.required'),
            },
          ]}
          hasFeedback
        >
          <Input placeholder={t('issue.report.form.title.placeholder')} />
        </Form.Item>

        <Form.Item
          name="description"
          label={t('issue.report.form.description.label')}
          rules={[
            {
              required: true,
              message: t('issue.report.form.description.required'),
            },
          ]}
          hasFeedback
        >
          <Input.TextArea
            rows={6}
            placeholder={t('issue.report.form.description.placeholder')}
          />
        </Form.Item>
        <Form.Item
          name="images"
          label={t('issue.report.form.only.png.message')}
          valuePropName="fileList"
          getValueFromEvent={uploadFile}
          help={t('issue.report.form.image.max.message')}
        >
          <Upload
            name="screenshot"
            listType="picture"
            beforeUpload={validateFileType}
            maxCount={10}
            multiple
          >
            <Button icon={<UploadOutlined />}>
              {t('issue.report.select.image')}
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}
