import { message } from 'antd';
import api from '@/services/lighthouse';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { usePlatform } from '@/context/PlatformContext';

function useMutationUpdateBudgetSettings() {
  const { t } = useTranslation();
  const { billing } = usePlatform();

  return useMutation(
    ({ provider, data }) =>
      api.post(
        `/data_connectors/${billing.id}/budget/settings`,
        {
          data,
        },
        {
          headers: {
            'context-provider': provider,
          },
        }
      ),
    {
      onMutate() {
        message.loading({
          content: t('message.updating'),
          key: 'updatable',
        });
      },
      onSuccess() {
        message.success({
          content: t('message.updating.success'),
          key: 'updatable',
          duration: 4,
        });
      },
      onError() {
        message.error({
          content: t('message.updating.error'),
          key: 'updatable',
          duration: 4,
        });
      },
    }
  );
}

export default useMutationUpdateBudgetSettings;
