import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('./home'));
const Users = lazy(() => import('./users'));

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={`/settings/groups`}
        title={t('settings:groups.access')}
        component={Home}
        configured={false}
      />
      <PrivateRoute
        exact
        path={`/settings/groups/:id/users`}
        title={t('settings:groups.users')}
        component={Users}
        configured={false}
      />
    </Switch>
  );
}

export default Routes;
