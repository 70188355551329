import { AzVMSymbol, AzSQLDataWarehouse } from 'azure-react-icons';
import AwsImgServices from '@/components/AwsImgServices/Index.jsx';

const autofixResources = new Map([
  [
    'EC2',
    {
      title: 'Start/Stop - EC2',
      label: 'EC2',
      code: 'EC2',
      kind: 'startstop',
      icon: <AwsImgServices kind="ec2" size={16} style={{ marginLeft: -2 }} />,
      actionsCodes: ['START_EC2_RESOURCE', 'STOP_EC2_RESOURCE'],
      providers: ['aws'],
    },
  ],
  [
    'RDS',
    {
      title: 'Start/Stop - RDS',
      label: 'RDS',
      code: 'RDS',
      kind: 'startstop',
      icon: (
        <AwsImgServices
          kind="rds"
          size={26}
          style={{ marginLeft: -6, marginRight: -3 }}
        />
      ),
      actionsCodes: ['START_RDS_RESOURCE', 'STOP_RDS_RESOURCE'],
      providers: ['aws'],
    },
  ],
  [
    'REDSHIFT',
    {
      title: 'Start/Stop - Redshift',
      label: 'Redshift',
      code: 'REDSHIFT',
      kind: 'startstop',
      icon: (
        <AwsImgServices kind="redshift" size={18} style={{ marginLeft: -2 }} />
      ),
      actionsCodes: ['START_REDSHIFT_RESOURCE', 'STOP_REDSHIFT_RESOURCE'],
      providers: ['aws'],
    },
  ],
  [
    'ASG',
    {
      title: 'Scheduled Scaling - Auto Scaling Groups',
      label: 'Auto Scaling Groups',
      code: 'ASG',
      kind: 'resize',
      icon: <AwsImgServices kind="asg" size={25} style={{ marginLeft: -4 }} />,
      actionsCodes: ['RESIZE_ASG_UP', 'RESIZE_ASG_DOWN'],
      providers: ['aws'],
    },
  ],
  [
    'ELASTICACHE',
    {
      title: 'Scheduled Scaling - ElastiCache',
      label: 'ElastiCache',
      code: 'ELASTICACHE',
      kind: 'resize',
      icon: (
        <AwsImgServices
          kind="elasticache"
          size={18}
          style={{ marginLeft: -2 }}
        />
      ),
      actionsCodes: ['RESIZE_ELASTICACHE_UP', 'RESIZE_ELASTICACHE_DOWN'],
      providers: ['aws'],
    },
  ],
  [
    'ELASTICSEARCH',
    {
      title: 'Scheduled Scaling - OpenSearch',
      label: 'OpenSearch',
      code: 'ELASTICSEARCH',
      kind: 'resize',
      icon: (
        <AwsImgServices
          kind="opensearch"
          size={18}
          style={{ marginLeft: -2 }}
        />
      ),
      actionsCodes: ['RESIZE_ELASTICSEARCH_UP', 'RESIZE_ELASTICSEARCH_DOWN'],
      providers: ['aws'],
    },
  ],
  [
    'RDSIOPS',
    {
      title: 'Scheduled Scaling - RDS IOPS',
      label: 'RDS IOPS',
      code: 'RDSIOPS',
      kind: 'resize',
      icon: (
        <AwsImgServices
          kind="rds"
          size={26}
          style={{ marginLeft: -6, marginRight: -3 }}
        />
      ),
      actionsCodes: ['RESIZE_RDS_IOPS_UP', 'RESIZE_RDS_IOPS_DOWN'],
      providers: ['aws'],
    },
  ],
  [
    'RESOURCES_GROUP',
    {
      title: 'autofix:start.stop.resources.groups',
      label: 'Resource Groups',
      code: 'RESOURCES_GROUP',
      kind: 'groups',
      actionsCodes: ['START_RESOURCES_GROUP', 'STOP_RESOURCES_GROUP'],
      providers: ['aws'],
    },
  ],
  [
    'VM',
    {
      title: 'Start/Stop - VM',
      label: 'VM',
      code: 'AZURE_VM',
      kind: 'startstop',
      icon: <AzVMSymbol size={24} />,
      actionsCodes: ['START_AZURE_VM_RESOURCE', 'STOP_AZURE_VM_RESOURCE'],
      providers: ['azure'],
    },
  ],
  [
    'DATABASE',
    {
      title: 'Start/Stop - Database',
      label: 'Database',
      code: 'AZURE_DATABASE',
      kind: 'startstop',
      icon: <AzSQLDataWarehouse size={24} />,
      actionsCodes: [
        'START_AZURE_DATABASE_RESOURCE',
        'STOP_AZURE_DATABASE_RESOURCE',
      ],
      providers: ['azure'],
    },
  ],
]);

export default autofixResources;
