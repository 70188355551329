import { useQuery } from 'react-query';
import api from '@/services/lighthouse';
import { usePlatform } from '@/context/PlatformContext';

export default function useFetchBudgetSettings({ enabled = true }) {
  const { billing } = usePlatform();

  return useQuery(
    ['budget', 'settings', billing.id],
    () =>
      api
        .get(`/data_connectors/${billing.id}/budget/settings`)
        .then((response) => response.data),
    {
      enabled,
      retry: 0,
      refetchOnMount: true,
    }
  );
}
