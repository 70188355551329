import amplitude from 'amplitude-js';

const tracking = {
  init() {
    if (process.env.REACT_APP_AMPLITUDE_TOKEN) {
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_TOKEN);
    }
  },
  setUserData(user) {
    try {
      amplitude.getInstance().setUserProperties({
        email: user.email,
        name: user.displayName,
        org_id: user?.org?.id,
        org_name: user?.org?.name || 'Pier Cloud Staff',
      });
    } catch (error) {
      console.error(error);
    }
  },
  logEvent({ event, user, properties }) {
    try {
      this.setUserData(user);
      const data = properties || {};
      amplitude.getInstance().logEvent(event, data);
    } catch (error) {
      console.error(error);
    }
  },
};

export default tracking;
