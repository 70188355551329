import { Spin } from 'antd';

export default function LoadingSpinner({ height = '100vh', size = 'large' }) {
  return (
    <div
      style={{
        display: 'flex',
        height,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin size={size} />
    </div>
  );
}
