import React from 'react';
import { Modal, Typography, Button } from 'antd';
import useMutationDeleteFeatureFlag from '@/mutations/useMutationDeleteFeatureFlag';
import { useModal } from '@/context/ModalContext';
import { useTranslation } from 'react-i18next';

function DeleteFeatureFlag({ onClose, featureFlag }) {
  const { t } = useTranslation();
  const featureFlagDelete = useMutationDeleteFeatureFlag();

  function handleSave() {
    featureFlagDelete.mutate(
      { id: featureFlag.id },
      {
        onSuccess() {
          onClose();
        },
      }
    );
  }

  return (
    <Modal
      title={t('common.delete.message.title')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('common.delete.button.cancel')}
        </Button>,
        <Button
          danger
          key="delete"
          onClick={handleSave}
          type="primary"
          loading={featureFlagDelete.isLoading}
        >
          {t('common.delete.button.submit')}
        </Button>,
      ]}
    >
      <Typography>{t('common.delete.message.content')}</Typography>
    </Modal>
  );
}

export default function ModalWrapper() {
  const modalName = 'DELETE_FEATURE_FLAG';
  const { getModal, getModalProps, closeModal } = useModal();
  const props = getModalProps(modalName);

  function handleClose() {
    closeModal(modalName);
  }

  if (!getModal(modalName)) {
    return null;
  }

  return <DeleteFeatureFlag onClose={handleClose} {...props} />;
}
