import axios from 'axios';

function createAPI(baseURL) {
  if (!baseURL) {
    throw new Error('Not possible to create api without base url');
  }

  const api = axios.create({
    baseURL,
    timeout: 300000,
  });

  api.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => Promise.reject(error)
  );

  return api;
}

export default createAPI;
