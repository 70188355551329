import React, { useMemo } from 'react';
import eks from '@/assets/images/aws/services/eks.svg';
import ec2 from '@/assets/images/aws/services/ec2.svg';
import ecs from '@/assets/images/aws/services/ecs.svg';
import beanstalk from '@/assets/images/aws/services/beanstalk.svg';
import rds from '@/assets/images/aws/services/rds.svg';
import asg from '@/assets/images/aws/services/asg.svg';
import elasticache from '@/assets/images/aws/services/elasticache.svg';
import opensearch from '@/assets/images/aws/services/opensearch.svg';
import spot from '@/assets/images/aws/services/spot.svg';
import redshift from '@/assets/images/aws/services/redshift.svg';
import memorydb from '@/assets/images/aws/services/memorydb.svg';
import sagemaker from '@/assets/images/aws/services/sagemaker.svg';
import spcompute from '@/assets/images/aws/services/spcompute.svg';

function AwsImgServices({ kind, size = 32, ...rest }) {
  const image = useMemo(() => {
    const switcher = {
      eks: eks,
      ec2: ec2,
      ecs: ecs,
      beanstalk: beanstalk,
      rds: rds,
      asg: asg,
      elasticache: elasticache,
      opensearch: opensearch,
      spot: spot,
      redshift: redshift,
      memorydb: memorydb,
      sagemaker: sagemaker,
      spcompute: spcompute,
    };
    return switcher[kind];
  }, [kind]);

  return <img src={image} alt={`Logo`} width={size} {...rest} />;
}

export default AwsImgServices;
