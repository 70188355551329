import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Accounts = lazy(() => import('./accounts'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/sp-manager/settings`}
        title="Settings"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/sp-manager/settings/accounts`}
        title="Settings Accounts"
        component={Accounts}
      />
    </>
  );
}

export default Routes;
