import { lazy } from 'react';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import { getAllProvidersKeys } from '@/common/cloudProviders';

const allProviders = getAllProvidersKeys();

const routes = [
  {
    path: '/lighthouse/budget/advanced-chargeback',
    exact: true,
    title: 'router.advanced.chargeback.title',
    component: lazy(() => import('./pages/home')),
    providers: allProviders,
  },
  {
    path: '/lighthouse/budget/advanced-chargeback/new',
    exact: true,
    title: 'router.advanced.chargeback.title',
    component: lazy(() => import('./pages/new')),
    providers: allProviders,
  },
  {
    path: '/lighthouse/budget/advanced-chargeback/:id',
    exact: true,
    title: 'router.advanced.chargeback.title',
    component: lazy(() => import('./pages/view')),
    providers: allProviders,
  },
  {
    path: [
      '/lighthouse/budget/advanced-chargeback/:data_connector_id/organizational-map/rules/new',
      '/lighthouse/budget/advanced-chargeback/:data_connector_id/organizational-map/rules/:rule_id',
      '/lighthouse/budget/advanced-chargeback/:data_connector_id/organizational-map/rules/:rule_id/duplicate',
    ],
    exact: true,
    title: 'menu.sidebar.maps',
    component: lazy(() => import('../budget/organizationalMap/pages/rules')),
    providers: allProviders,
  },
  {
    path: '/lighthouse/budget/advanced-chargeback/:data_connector_id/organizational-map',
    exact: true,
    title: 'menu.sidebar.maps',
    component: lazy(() => import('../budget/organizationalMap/pages/home')),
    providers: allProviders,
  },
  {
    path: '/lighthouse/budget/advanced-chargeback/:id/actions',
    exact: true,
    title: 'router.advanced.chargeback.title',
    component: lazy(() => import('../../settings/dataConnectors/actions')),
    providers: allProviders,
  },
];

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      {routes.map(({ title, ...rest }) => (
        <PrivateRoute key={rest.path} title={t(title)} {...rest} />
      ))}
    </Switch>
  );
}

export default Routes;
