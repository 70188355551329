import CheckersRoutes from './checkers/Routes';
import InsightsRoutes from './insights/Routes';
import LogsRoutes from './logs/Routes';
import PaymentPlansRoutes from './paymentPlans/Routes';
import SmartInstancesRoutes from './smartInstances/Routes';
import { Redirect, Switch, Route } from 'react-router-dom/cjs/react-router-dom';

function Routes() {
  return (
    <Switch>
      <Route path={['/cca/data-connectors*']}>
        <CheckersRoutes />
      </Route>

      <Route path="/cca/insights*">
        <InsightsRoutes />
      </Route>

      <Route path="/cca/logs*">
        <LogsRoutes />
      </Route>

      <Route path="/cca/smart-instances*">
        <SmartInstancesRoutes />
      </Route>

      <Route path={['/cca/reservations*', '/cca/savings-plans*']}>
        <PaymentPlansRoutes />
      </Route>

      <Redirect exact from="/cca" to="/cca/data-connectors/overview" />
    </Switch>
  );
}

export default Routes;
