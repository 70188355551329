import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Asgs = lazy(() => import('./home'));
const AsgSettings = lazy(() => import('./asgSettings'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/spot/asgs/:asgType`}
        title="Auto Scaling Groups"
        component={Asgs}
      />

      <PrivateRoute
        exact
        path={`/spot/asgs/:asgType/:asgId/settings`}
        title="Auto Scaling Groups"
        component={AsgSettings}
      />
    </>
  );
}

export default Routes;
