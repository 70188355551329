import {
  ContainerOutlined,
  ControlOutlined,
  FileTextOutlined,
  SettingOutlined,
  AreaChartOutlined,
} from '@ant-design/icons';
import returnOnlyVisibleMenuItems from '@/common/onlyVisibleMenuItems';
import AwsImgServices from '@/components/AwsImgServices/Index.jsx';
import AzureImgServices from '@/components/AzureImgServices/Index.jsx';

const items = ({ t, adminRole, provider, featureFlags }) => {
  const allItems = [
    {
      key: 'cca/data-connectors/overview',
      label: t('menu.sidebar.overview'),
      icon: <AreaChartOutlined />,
    },
    {
      key: 'cca/data-connectors/checkers',
      label: 'Checkers',
      icon: <ControlOutlined />,
    },
    {
      key: 'cca/insights',
      label: 'Rightsizing',
      icon: <ContainerOutlined />,
      providers: ['azure', 'aws'],
      children: [
        {
          key: `cca/insights/rightsizing/vms`,
          label: 'VM',
          icon: (
            <AzureImgServices kind="vm" size={20} style={{ marginLeft: -2 }} />
          ),
          providers: ['azure'],
        },
        {
          key: `cca/insights/rightsizing/redis`,
          label: 'Redis',
          icon: (
            <AzureImgServices
              kind="redis"
              size={20}
              style={{ marginLeft: -2 }}
            />
          ),
          providers: ['azure'],
        },
        {
          key: `cca/insights/rightsizing/disk`,
          label: 'Disk',
          icon: (
            <AzureImgServices
              kind="disk"
              size={20}
              style={{ marginLeft: -2 }}
            />
          ),
          providers: ['azure'],
        },
        {
          key: `cca/insights/rightsizing/ec2`,
          label: 'EC2',
          icon: (
            <AwsImgServices kind="ec2" size={16} style={{ marginLeft: -2 }} />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/insights/rightsizing/rds`,
          label: 'RDS',
          icon: (
            <AwsImgServices
              kind="rds"
              size={26}
              style={{ marginLeft: -5, marginRight: -3 }}
            />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/insights/rightsizing/elasticache`,
          label: 'ElastiCache',
          icon: <AwsImgServices kind="elasticache" size={18} />,
          providers: ['aws'],
          hide: featureFlags.has('rightsizing-elasticache') ? true : undefined,
        },
        {
          key: 'cca/insights/rightsizing/databases',
          label: 'Database',
          icon: (
            <AzureImgServices kind="sql" size={22} style={{ marginLeft: -2 }} />
          ),
          providers: ['azure'],
          children: [
            {
              key: `cca/insights/rightsizing/databases/mysql_postgresql`,
              label: 'MySQL/PostgreSQL',
            },
            {
              key: `cca/insights/rightsizing/databases/sql_server`,
              label: 'SQL Server',
              hide: featureFlags.has('rightsizing-azure-sql-server')
                ? true
                : undefined,
            },
          ],
        },
      ],
    },
    {
      key: 'cca/reservations',
      label: t('menu.sidebar.reservations'),
      icon: <ContainerOutlined />,
      providers: ['aws'],
      children: [
        {
          key: `cca/reservations/ec2`,
          label: 'EC2',
          icon: (
            <AwsImgServices kind="ec2" size={16} style={{ marginLeft: -2 }} />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/reservations/rds`,
          label: 'RDS',
          icon: (
            <AwsImgServices kind="rds" size={22} style={{ marginLeft: -4 }} />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/reservations/redshift`,
          label: 'Redshift',
          icon: (
            <AwsImgServices
              kind="redshift"
              size={18}
              style={{ marginLeft: -2 }}
            />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/reservations/elasticache`,
          label: 'ElastiCache',
          icon: (
            <AwsImgServices
              kind="elasticache"
              size={18}
              style={{ marginLeft: -2 }}
            />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/reservations/opensearch`,
          label: 'Opensearch',
          icon: (
            <AwsImgServices
              kind="opensearch"
              size={18}
              style={{ marginLeft: -2 }}
            />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/reservations/memorydb`,
          label: 'MemoryDB',
          icon: (
            <AwsImgServices
              kind="memorydb"
              size={18}
              style={{ marginLeft: -2 }}
            />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/reservations/settings`,
          label: 'Reservations Settings',
          icon: <SettingOutlined />,
          providers: ['aws'],
        },
      ],
    },
    {
      key: 'cca/savings-plans',
      label: t('menu.sidebar.sps'),
      icon: <ContainerOutlined />,
      providers: ['aws'],
      children: [
        {
          key: `cca/savings-plans/compute`,
          label: 'Compute',
          icon: (
            <AwsImgServices
              kind="spcompute"
              size={20}
              style={{ marginLeft: -2 }}
            />
          ),
          providers: ['aws'],
        },
        {
          key: `cca/savings-plans/ec2instance`,
          label: 'EC2 Instance',
          icon: <AwsImgServices kind="ec2" size={16} />,
          providers: ['aws'],
        },
        {
          key: `cca/savings-plans/sagemaker`,
          label: 'Sagemaker',
          icon: <AwsImgServices kind="sagemaker" size={20} />,
          providers: ['aws'],
        },
        {
          key: `cca/savings-plans/settings`,
          label: 'Savings Plans Settings',
          icon: <SettingOutlined />,
          providers: ['aws'],
        },
      ],
    },
    {
      key: 'cca/smart-instances',
      label: 'Smart Instances',
      icon: <ContainerOutlined />,
      providers: ['azure'],
      hide: featureFlags.has('smart-instances') ? true : undefined,
      children: [
        {
          key: `cca/smart-instances/vms`,
          label: 'VM',
          icon: (
            <AzureImgServices kind="vm" size={20} style={{ marginLeft: -2 }} />
          ),
          providers: ['azure'],
        },
      ],
    },
    {
      key: 'cca/logs',
      label: 'Logs',
      icon: <FileTextOutlined />,
      providers: ['azure', 'aws', 'gcp'],
    },
  ];

  return returnOnlyVisibleMenuItems({ items: allItems, adminRole, provider });
};

export default items;
