export default function responseErrorHandler(error) {
  const path = window.location.pathname;
  const search = window.location.search;

  if (error.response?.status === 401) {
    localStorage.removeItem('auth');
    if (!path.includes('/login')) {
      window.location = `/login?redirect=${path}${search}`;
    }
  }

  if (error.response?.status === 403) {
    const url = new URL(window.location);

    url.searchParams.set('error', '403');
    window.history.pushState('', '', url);
    let event = new Event('error403', { bubbles: true }); // (2)
    document.dispatchEvent(event);
  }

  return Promise.reject(error);
}
