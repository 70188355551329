function getOnlyVisibleMenuItems({ items, adminRole, provider }) {
  return items.reduce((visibles, item) => {
    if (item.hide) {
      return visibles;
    }

    if (
      Array.isArray(item.permissions) &&
      item.permissions.length &&
      !item.permissions.includes(adminRole)
    ) {
      return visibles;
    }

    if (
      item.global === undefined &&
      Array.isArray(item.providers) &&
      item.providers.length &&
      !item.providers.includes(provider)
    ) {
      return visibles;
    }

    const copy = { ...item };

    if (Array.isArray(copy.children) && copy.children.length) {
      copy.children = getOnlyVisibleMenuItems({
        items: copy.children,
        adminRole,
        provider,
      });
    }

    return [...visibles, copy];
  }, []);
}

export default getOnlyVisibleMenuItems;
