import AsgRoutes from './asgs/Routes';
import MarketRoutes from './market/Routes';
import LogsRoutes from './logs/Routes';
import StandaloneRoutes from './standalone/Routes';
import GlobalSettingsRoutes from './globalSettings/Routes';
import { Redirect, Switch, Route } from 'react-router-dom/cjs/react-router-dom';

function Routes() {
  return (
    <Switch>
      <Route path="/spot/asgs*">
        <AsgRoutes />
      </Route>

      <Route path="/spot/market*">
        <MarketRoutes />
      </Route>

      <Route path="/spot/logs*">
        <LogsRoutes />
      </Route>

      <Route path="/spot/standalone">
        <StandaloneRoutes />
      </Route>

      <Route path="/spot/settings">
        <GlobalSettingsRoutes />
      </Route>

      <Redirect exact from="/spot" to="/spot/asgs/ec2" />
    </Switch>
  );
}

export default Routes;
