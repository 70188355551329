import { AuthProvider } from './AuthContext';
import { ModalProvider } from './ModalContext';
import { PlatformProvider } from './PlatformContext';

export function AppProvider({ children }) {
  return (
    <AuthProvider>
      <ModalProvider>
        <PlatformProvider>{children}</PlatformProvider>
      </ModalProvider>
    </AuthProvider>
  );
}
