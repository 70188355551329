import { t } from 'i18next';

export function getProviders(providerKey, except = []) {
  let providersList = [
    {
      key: 'aws',
      alias: 'AWS',
      name: 'AWS (Amazon Web Services)',
      shortName: 'Amazon Web Services',
      billing: 'aws-billing',
      regions: [
        'us-east-1',
        'us-east-2',
        'us-west-1',
        'us-west-2',
        'sa-east-1',
        // 'af-south-1',
        // 'ap-east-1',
        // 'ap-northeast-1',
        // 'ap-northeast-2',
        // 'ap-northeast-3',
        // 'ap-southeast-1',
        // 'ap-southeast-2',
        // 'ap-southeast-3',
        // 'ca-central-1',
        // 'eu-central-1',
        // 'eu-north-1',
        // 'eu-south-1',
        // 'eu-west-1',
        // 'eu-west-2',
        // 'me-central-1',
        // 'me-south-1',
        // 'sa-east-1',
        // 'us-east-1',
        // 'us-east-2',
        // 'us-gov-east-1',
        // 'us-gov-west-1',
        // 'us-west-1',
        // 'us-west-2',
      ],
    },
    {
      key: 'azure',
      alias: 'Azure',
      shortName: 'Microsoft Azure',
      name: 'Azure (Microsoft Azure)',
      billing: 'azure-billing',
      regions: [
        'Central US',
        'East US',
        'East US 2',
        'US Gov Iowa',
        'US Gov Virginia',
        'North Central US',
        'South Central US',
        'West US',
        'North Europe',
        'West Europe',
        'East Asia',
        'Southeast Asia',
        'Japan East',
        'Japan West',
        'Brazil South',
        'Australia East',
        'Australia Southeast',
        'Central India',
        'South India',
        'West India',
      ],
    },
    {
      key: 'gcp',
      alias: 'GCP',
      shortName: 'Google Cloud Platform',
      name: 'GCP (Google Cloud Platform)',
      billing: 'gcp-billing',
      regions: [],
    },
    {
      key: 'oci',
      alias: 'OCI',
      shortName: 'Oracle Cloud Infrastructure',
      name: 'OCI (Oracle Cloud Infrastructure)',
      billing: 'oci-billing',
      regions: [],
    },
    {
      key: 'onpremise',
      alias: 'On Premise',
      shortName: 'On Premise',
      name: 'On Premise',
      billing: 'onpremise-billing',
      regions: [],
    },
    {
      key: 'focus',
      alias: 'FOCUS',
      shortName: 'FOCUS',
      name: 'FOCUS',
      billing: 'focus',
      regions: [],
    },
  ];

  if (Array.isArray(except) && except.length) {
    providersList = providersList.filter(
      (provider) => !except.includes(provider.key)
    );
  }

  if (providerKey) {
    return providersList.find((provider) => provider.key === providerKey);
  }

  return providersList;
}

export function getCloudProviderName(key) {
  const provider = getProviders(key);
  return provider?.name || 'Desconhecido';
}

export const getTypes = () => [
  {
    key: 'payer_account',
    name: t('cloud.name.payer_account'),
  },
  {
    key: 'resource_account',
    name: t('cloud.name.resource_account'),
  },
];

export function getCloudProviderType(key) {
  const type = getTypes().find((type) => type.key === key);
  return type ? type.name : 'Desconhecido';
}

export function getProviderRegions(providerKey) {
  const provider = getProviders(providerKey);

  if (!provider) {
    throw new Error('Provider not found: ', providerKey);
  }

  return provider.regions;
}

export function getAllProvidersKeys({ except } = { except: [] }) {
  const providers = getProviders().map((provider) => provider.key);

  if (Array.isArray(except) && except.length) {
    return providers.filter((provider) => !except.includes(provider));
  }

  return providers;
}
