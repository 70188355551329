import { Switch, Route, Redirect } from 'react-router-dom';
import InfrastructureAnomaliesRoutes from './infrastructure/Routes';
import BusinnesAnomaliesRoutes from './business/Routes';

function Routes() {
  return (
    <Switch>
      <Route path="/lighthouse/anomalies/infrastructure*">
        <InfrastructureAnomaliesRoutes />
      </Route>

      <Route path="/lighthouse/anomalies/business*">
        <BusinnesAnomaliesRoutes />
      </Route>

      <Redirect
        from="/lighthouse/anomalies"
        to="/lighthouse/anomalies/infrastructure"
      />
    </Switch>
  );
}

export default Routes;
