import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/autofix/tag-manager`}
        title="Tag Manager"
        component={Home}
      />
    </>
  );
}

export default Routes;
