import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const StandaloneHome = lazy(() => import('./home'));
const StandaloneInstances = lazy(() => import('./instances'));
const StandaloneSettings = lazy(() => import('./settings'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/spot/standalone`}
        title="Spot Standalone"
        component={StandaloneHome}
      />
      <PrivateRoute
        exact
        path={`/spot/standalone/settings`}
        title="Spot Standalone Configurações"
        component={StandaloneSettings}
      />
      <PrivateRoute
        exact
        path={`/spot/standalone/settings/:settingId`}
        title="Spot Standalone Configurações"
        component={StandaloneInstances}
      />
    </>
  );
}

export default Routes;
