import moment from 'moment';
import { useEffect, useState } from 'react';

const dateFormat = 'YYYY-MM-DD';
const storeName = 'calendarRange';

function initializer(propName, initialValue) {
  const store = JSON.parse(localStorage.getItem(storeName));
  return store?.[propName] ?? initialValue;
}

function useCalendarRange() {
  const [startDate, setStartDate] = useState(
    initializer('startDate', moment().startOf('month').format(dateFormat))
  );
  const [endDate, setEndDate] = useState(
    initializer('endDate', moment().endOf('month').format(dateFormat))
  );
  const [calendarType, setCalendarType] = useState(
    initializer('calendarType', 'month')
  );

  useEffect(() => {
    const store = JSON.stringify({
      startDate,
      endDate,
      calendarType,
    });
    localStorage.setItem(storeName, store);
  }, [startDate, endDate, calendarType]);

  useEffect(() => {
    if (calendarType === 'month') {
      const start = moment(startDate).startOf('month').format(dateFormat);
      const end = moment(endDate).endOf('month').format(dateFormat);
      setStartDate(start);
      setEndDate(end);
    }
  }, [calendarType, endDate, startDate]);

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    calendarType,
    setCalendarType,
  };
}

export default useCalendarRange;
