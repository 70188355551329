import { useContext, createContext, useState } from 'react';

const ModalContext = createContext();

export function useModal() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }) {
  const provider = useProviderModal();

  return (
    <ModalContext.Provider value={provider}>{children}</ModalContext.Provider>
  );
}

function useProviderModal() {
  const [modals, setModals] = useState(new Map());

  const getModal = (name) => {
    return modals.has(name);
  };

  const getModalProps = (name) => {
    return modals.get(name);
  };

  const openModal = ({ name, ...props }) => {
    setModals((prev) => {
      prev.set(name, props);
      return new Map(prev);
    });
  };

  const closeModal = (name) => {
    setModals((prev) => {
      prev.delete(name);
      return new Map(prev);
    });
  };

  return {
    modals,
    setModals,
    getModal,
    getModalProps,
    openModal,
    closeModal,
  };
}
