import CreateFeatureFlags from './CreateFeatureFlags';
import DeleteFeatureFlag from './DeleteFeatureFlag';
import BudgetSettingsModal from './BudgetSettingsModal';

function Modals() {
  return (
    <>
      <CreateFeatureFlags />
      <DeleteFeatureFlag />
      <BudgetSettingsModal />
    </>
  );
}

export default Modals;
