import TagManagerRoutes from './tagsManager/Routes';
import AutoTagsRoutes from './autoTags/Routes';
import StartStopRoutes from './startStop/Routes';
import LogsRoutes from './logs/Routes';
import SmartStopRoutes from './smartStop/Routes';
import { usePlatform } from '@/context/PlatformContext';
import { Redirect, Switch, Route } from 'react-router-dom/cjs/react-router-dom';

function Routes() {
  const { context } = usePlatform();

  return (
    <Switch>
      <Route path={['/autofix/smart-stop*']}>
        <SmartStopRoutes />
      </Route>

      <Route path={['/autofix/tag-manager*']}>
        <TagManagerRoutes />
      </Route>

      <Route
        path={[
          '/autofix/start-stop*',
          '/autofix/scheduled-scaling*',
          '/autofix/resource-groups*',
        ]}
      >
        <StartStopRoutes />
      </Route>

      <Route path={['/autofix/auto-tags*']}>
        <AutoTagsRoutes />
      </Route>

      <Route path={['/autofix/logs*']}>
        <LogsRoutes />
      </Route>

      {context?.provider === 'aws' && (
        <Redirect from="/autofix" to="/autofix/tag-manager" />
      )}

      {context?.provider === 'azure' && (
        <Redirect from="/autofix" to="/autofix/auto-tags" />
      )}
    </Switch>
  );
}

export default Routes;
