import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Upsert = lazy(() => import('./upsert'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/lighthouse/lia/actions`}
        title="LIA Actions"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/lighthouse/lia/actions/create`}
        title="LIA Actions Create"
        component={Upsert}
      />
      <PrivateRoute
        exact
        path={`/lighthouse/lia/actions/edit/:id`}
        title="LIA Actions Edit"
        component={Upsert}
      />
    </>
  );
}

export default Routes;
