import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import theme from './theme';
import { ThemeProvider } from 'react-jss';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorBoundary from './components/ErrorBoundary';
import './index.less';
import './i18nextConf';

import { AppProvider } from './context/index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const rootElement = document.getElementById('root');

//remove all new lines or tabs from string when copy values within root element
rootElement.addEventListener('copy', (event) => {
  const selection = document.getSelection();

  event.clipboardData.setData(
    'text/plain',
    selection.toString().trim().replace(/[\r]/gm, '')
  );

  event.preventDefault();
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <ErrorBoundary>
            <StrictMode>
              <App />
            </StrictMode>
          </ErrorBoundary>
        </AppProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </QueryClientProvider>,
  rootElement
);
