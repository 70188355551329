import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/settings/business-units`}
        title="Business Units"
        component={Home}
      />
    </>
  );
}

export default Routes;
