import { Space } from 'antd';

export default function VerticalSpace({
  size,
  width,
  children,
  style,
  className,
}) {
  return (
    <Space
      size={size}
      direction="vertical"
      className={className}
      style={{ width: width ?? '100%', ...style }}
    >
      {children}
    </Space>
  );
}
