import { lazy, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import { getAllProvidersKeys } from '@/common/cloudProviders';

const routes = [
  {
    path: '/space/journeys',
    title: 'router.space.dashboard',
    exact: true,
    component: lazy(() => import('./home')),
  },
  {
    path: '/space/journeys/:journeyId',
    title: 'router.journey.subpage',
    exact: true,
    component: lazy(() => import('./journey')),
  },
  {
    path: '/space/journeys/:journeyId/capabilities/:id',
    title: 'router.journey.capability.subpage',
    exact: true,
    component: lazy(() => import('./capability')),
  },
  {
    path: [
      '/space/journeys/:journeyId/capabilities/:capabilityId/actions/new',
      '/space/journeys/:journeyId/capabilities/:capabilityId/actions/:action',
    ],
    exact: true,
    title: 'router.journey.action.subpage',
    component: lazy(() => import('./createAction')),
  },
  {
    path: '/space/journeys/action/create',
    title: 'router.journey.subpage',
    exact: true,
    component: lazy(() => import('./createAction')),
  },
];

function Routes() {
  const { t } = useTranslation();

  const allProviders = useMemo(() => getAllProvidersKeys(), []);

  return (
    <Switch>
      <Route path="/space/journeys*">
        <Switch>
          {routes.map(({ title, ...rest }) => (
            <PrivateRoute
              key={rest.path}
              title={t(title)}
              {...rest}
              providers={allProviders}
            />
          ))}
        </Switch>
      </Route>

      <Redirect from="/space" to="/space/journeys" />
    </Switch>
  );
}

export default Routes;
