import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const ReservationHome = lazy(() => import('./reservations/home'));
const ReservationSettings = lazy(() => import('./reservations/settings'));
const SavingsPlansHome = lazy(() => import('./savingsPlans/home'));
const SavingsPlansSettings = lazy(() => import('./savingsPlans/settings'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/cca/reservations/settings`}
        title="CCA Reservations Settings"
        component={ReservationSettings}
      />
      <PrivateRoute
        exact
        path={`/cca/reservations/:kind`}
        title="CCA Reservations"
        component={ReservationHome}
      />
      <PrivateRoute
        exact
        path={`/cca/savings-plans/settings`}
        title="CCA Savings Plans Settings"
        component={SavingsPlansSettings}
      />
      <PrivateRoute
        exact
        path={`/cca/savings-plans/:kind`}
        title="CCA Savings Plans"
        component={SavingsPlansHome}
      />
    </>
  );
}

export default Routes;
