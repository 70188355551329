import { useEffect, useMemo, useState } from 'react';
import useFetchFields from '@/queries/useFetchFields';
import { usePlatform } from '@/context/PlatformContext';

export default function useFieldsList({
  dataTypes = [],
  dataConnectorsKinds,
  removeCustoms = false,
  removeBoosts = false,
  showOnlyTags = false,
  workspaceId,
  datasetId,
  dataConnectorId,
  enabled = true,
  sorted = true,
}) {
  const { business, context } = usePlatform();

  const [pinnedFields, setPinnedFields] = useState([]);

  const fieldsData = useFetchFields({
    dataTypes,
    dataConnectorsKinds,
    removeCustoms,
    removeBoosts,
    showOnlyTags,
    workspaceId,
    datasetId,
    dataConnectorId,
    sorted,
    contextId: context?.id,
    contextProvider: context?.provider,
    enabled: !!business && enabled,
  });

  const fieldsList = useMemo(() => {
    if (!fieldsData.isSuccess) {
      return [];
    }

    return fieldsData.data || [];
  }, [fieldsData.isSuccess, fieldsData.data]);

  useEffect(() => {
    const fromStore = getPinnedsFromStore();
    const pinneds = fieldsList.filter((field) =>
      fromStore.some((item) => item.name === field.name)
    );
    setPinnedFields(pinneds);
  }, [fieldsList]);

  const notPinnedFields = useMemo(() => {
    if (!fieldsList.length) {
      return [];
    }

    return fieldsList.filter(
      (item) => !pinnedFields.some((field) => field.name === item.name)
    );
  }, [fieldsList, pinnedFields]);

  useEffect(() => {
    setPinnedsToStore(pinnedFields, fieldsList);
  }, [fieldsList, pinnedFields]);

  function PinField(field) {
    setPinnedFields([...pinnedFields, field]);
  }

  function unPinField(field) {
    setPinnedFields(pinnedFields.filter((item) => item.name !== field.name));
  }

  return {
    ...fieldsData,
    fieldsList,
    pinnedFields,
    notPinnedFields,
    PinField,
    unPinField,
  };
}

function getPinnedsFromStore() {
  return JSON.parse(localStorage.getItem('pinned-fields'));
}

function setPinnedsToStore(pinnedFields = [], fieldsList = []) {
  if (!fieldsList.length) {
    return;
  }

  const normalized = pinnedFields.flatMap((item) => {
    if ('kind' in item) {
      return [item];
    }

    const field = fieldsList.find((field) => field.name === item.name);

    if (!field) {
      return [];
    }

    return [field];
  });

  const store = JSON.stringify(normalized);
  localStorage.setItem('pinned-fields', store);
}
