import { lazy, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';

function Routes() {
  const { t } = useTranslation();

  const routes = useMemo(
    () => [
      {
        path: [
          '/lighthouse/workspaces/:workspace/view/new',
          '/lighthouse/workspaces/:workspace/view/:view',
        ],
        exact: true,
        title: 'BI',
        component: lazy(() => import('./bi')),
        renderLayout: false,
      },
      {
        path: '/lighthouse/workspaces/:workspace',
        exact: true,
        title: t('common.workspaces'),
        component: lazy(() => import('./workspace')),
      },
      {
        path: '/lighthouse/workspaces',
        exact: true,
        title: t('common.workspaces'),
        component: lazy(() => import('./home')),
      },
      {
        path: '/lighthouse/workspaces/group/:group_id',
        exact: true,
        title: t('common.workspaces'),
        component: lazy(() => import('./group')),
      },
    ],
    [t]
  );

  return (
    <Switch>
      {routes.map((route) => (
        <PrivateRoute key={route.path} {...route} />
      ))}
    </Switch>
  );
}

export default Routes;
