import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Split = lazy(() => import('./split'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/settings/businesses`}
        title="Businesses"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/settings/businesses/:id/split`}
        title="Split Business"
        component={Split}
      />
    </>
  );
}

export default Routes;
