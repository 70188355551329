function pxToRem(...args) {
  return args
    .flatMap((arg) => {
      if (typeof arg === 'string') {
        return arg
          .split(' ')
          .map((el) => `${parseInt(el.replace(/[^\d.]/g, '')) / 16}rem`);
      }

      return `${arg / 16}rem`;
    })
    .join(' ');
}

export default pxToRem;
