import { RocketOutlined } from '@ant-design/icons';
import { getAllProvidersKeys } from '@/common/cloudProviders';
import returnOnlyVisibleMenuItems from '@/common/onlyVisibleMenuItems';

const items = ({ t, userRole, provider }) => {
  const providers = getAllProvidersKeys();

  const allItems = [
    {
      key: 'space/journeys',
      label: t('menu.sidebar.journeys'),
      icon: <RocketOutlined />,
      providers,
    },
  ];

  return returnOnlyVisibleMenuItems({ items: allItems, userRole, provider });
};

export default items;
