import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Insights = lazy(() => import('./insights'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/cca/smart-instances/vms`}
        title="Smart Instances - VMs"
        component={Home}
        componentProps={{ resourceCode: 'vm' }}
      />
      <PrivateRoute
        exact
        path={`/cca/smart-instances/vms/:id/insights`}
        title="Smart Instances - Insights - VMs"
        component={Insights}
        componentProps={{ resourceCode: 'vm' }}
      />
    </>
  );
}

export default Routes;
