import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { apiWithoutBaseUrl } from '@/services/lighthouse';
import { useTranslation } from 'react-i18next';

export default function useMutationCreateFeatureFlag() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation(
    ({ alias, customers }) =>
      apiWithoutBaseUrl.post(`/feature_flags`, { alias, customers }),
    {
      onMutate() {
        message.loading({
          content: t('rule.message.creating'),
          key: 'feature-flags-create',
        });
      },
      onSuccess() {
        queryClient.invalidateQueries(['feature-flags']);
        message.success({
          content: t('rule.message.creating.success'),
          key: 'feature-flags-create',
          duration: 4,
        });
      },
      onError(error) {
        message.error({
          content: t('rule.message.creating.error'),
          key: 'feature-flags-create',
          duration: 4,
        });
      },
    }
  );
}
