import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Override = lazy(() => import('./override'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/autofix/smart-stop/ec2`}
        title="Smart Stop - EC2"
        component={Home}
        componentProps={{ resourceCode: 'ec2' }}
      />
      <PrivateRoute
        exact
        path={`/autofix/smart-stop/rds`}
        title="Smart Stop - RDS"
        component={Home}
        componentProps={{ resourceCode: 'rds' }}
      />
      <PrivateRoute
        exact
        path={`/autofix/smart-stop/asg`}
        title="Smart Stop - ASG"
        component={Home}
        componentProps={{ resourceCode: 'asg' }}
      />
      <PrivateRoute
        exact
        path={`/autofix/smart-stop/override`}
        title="Smart Stop - Override"
        component={Override}
      />
    </>
  );
}

export default Routes;
