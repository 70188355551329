import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';

const Home = lazy(() => import('./home'));
const Result = lazy(() => import('./result'));

function Routes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/lia/reports'}
        title="LIA Reports"
        component={Home}
      />

      <PrivateRoute
        exact
        path={'/lighthouse/lia/reports/settings/:id/result'}
        title="LIA Report"
        component={Result}
      />
    </Switch>
  );
}

export default Routes;
