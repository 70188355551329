import { FileTextOutlined, SettingOutlined } from '@ant-design/icons';
import returnOnlyVisibleMenuItems from '@/common/onlyVisibleMenuItems';
import AwsImgServices from '@/components/AwsImgServices/Index.jsx';

const items = ({ t, adminRole, provider, featureFlags, collapsed }) => {
  const allItems = [
    {
      key: `spot/asgs/ec2`,
      label: 'Auto Scaling Groups',
      icon: <AwsImgServices kind="asg" size={25} style={{ marginLeft: -4 }} />,
      providers: ['aws'],
    },
    {
      key: `spot/asgs/ecs`,
      label: 'ECS',
      icon: <AwsImgServices kind="ecs" size={25} style={{ marginLeft: -4 }} />,
      providers: ['aws'],
    },
    {
      key: `spot/asgs/eks`,
      label: 'EKS',
      icon: <AwsImgServices kind="eks" size={18} style={{ marginLeft: -1 }} />,
      providers: ['aws'],
    },
    {
      key: `spot/asgs/beanstalk`,
      label: 'Beanstalk',
      icon: (
        <AwsImgServices kind="beanstalk" size={22} style={{ marginLeft: -4 }} />
      ),
      providers: ['aws'],
    },
    {
      key: `spot/standalone`,
      label: 'Standalone',
      icon: <AwsImgServices kind="ecs" size={25} style={{ marginLeft: -4 }} />,
      providers: ['aws'],
    },
    {
      key: `spot/market`,
      label: 'Spot Market',
      icon: <AwsImgServices kind="spot" size={32} style={{ marginLeft: -8 }} />,
      providers: ['aws'],
    },
    {
      key: `spot/settings`,
      label: 'Configurações globais',
      icon: <SettingOutlined />,
      providers: ['aws'],
    },
    {
      key: 'spot/logs',
      label: 'Logs',
      icon: <FileTextOutlined />,
      providers: ['aws'],
    },
  ];

  return returnOnlyVisibleMenuItems({
    items: allItems,
    adminRole,
    provider,
  });
};

export default items;
