import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePlatform } from '@/context/PlatformContext';
import { useQueryClient } from 'react-query';

import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Popover, Typography, Button, Input, Divider, List, Space } from 'antd';

import { createUseStyles } from 'react-jss';

import useFetchContexts from '@/modules/settings/contexts/home/queries/useFetchContexts';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = createUseStyles(() => ({
  listItem: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f8f8f8',
    },
    '&.selected': {
      backgroundColor: '#e6f8ff',
      '& span': {
        color: '#1174c0',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
  },
  button: {
    borderRadius: 16,
    height: 40,
    minWidth: 90,
  },
  label: {
    position: 'absolute',
    zIndex: '99',
    background: 'white',
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: '700',
    top: '6px',
    left: '12px',
    padding: '0 4px',
  },
}));

function SwitchContext() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const classes = useStyles();
  const { contextLocked, context, setContext } = usePlatform();
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);

  const contextsQuery = useFetchContexts();

  useEffect(() => {
    if (!context && contextsQuery.isSuccess) {
      setContext(contextsQuery.data.contexts[0]);
    }
  }, [contextsQuery, context, setContext]);

  const contextList = useMemo(() => {
    if (contextsQuery.isSuccess) {
      return contextsQuery.data.contexts.filter((item) =>
        item?.name.toLowerCase().includes(search?.toLowerCase())
      );
    }

    return [];
  }, [contextsQuery, search]);

  const handleChangeContext = (value) => () => {
    if (context?.id === value && !contextLocked) {
      return;
    }

    const selected = contextList.find((item) => item.id === value);
    setContext(selected || null);
    setVisible(false);
    history.push(`/lighthouse/workspaces`);
    queryClient.invalidateQueries();
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleToggleContextList = (visible) => {
    setVisible(visible && contextList?.length > 1);
  };

  return (
    <Popover
      open={visible}
      placement="bottomRight"
      trigger="click"
      onOpenChange={handleToggleContextList}
      content={
        <div style={{ width: 280, maxWidth: 280 }}>
          <Input
            autoFocus
            allowClear
            value={search}
            placeholder={t('common.search.context')}
            onChange={handleChangeSearch}
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,0.3)' }} />}
            style={{ borderRadius: '16px' }}
          />

          <div style={{ margin: '8px -16px -12px -16px' }}>
            <Divider style={{ margin: 0 }} />
            <List
              size="small"
              dataSource={contextList}
              style={{
                overflow: 'hidden auto',
                maxHeight: '250px',
              }}
              renderItem={({ id, name }) => (
                <List.Item
                  onClick={handleChangeContext(id)}
                  className={clsx(classes.listItem, {
                    selected: !contextLocked && id === context?.id,
                  })}
                >
                  <Typography.Text ellipsis={{ tooltip: name }}>
                    {name}
                  </Typography.Text>
                </List.Item>
              )}
            />
          </div>
        </div>
      }
    >
      <div style={{ position: 'relative' }}>
        <label className={classes.label}>{t('common.context')}</label>
        <Button className={classes.button}>
          <Space>
            {contextLocked ? 'Global' : context?.name}
            {contextList?.length > 1 && (
              <CaretDownOutlined rotate={visible ? 180 : 0} />
            )}
          </Space>
        </Button>
      </div>
    </Popover>
  );
}

export default SwitchContext;
