import { useState, useMemo } from 'react';
import { usePlatform } from '@/context/PlatformContext';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Popover, Space, Typography, Button, Input, Divider, List } from 'antd';

import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = createUseStyles(() => ({
  listItem: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f8f8f8',
    },
    '&.selected': {
      backgroundColor: '#e6f8ff',
      '& span': {
        color: '#1174c0',
      },
      '&:hover': {
        cursor: 'default',
      },
    },
  },
  button: {
    borderRadius: 16,
    height: 40,
    minWidth: 90,
  },
  label: {
    position: 'absolute',
    zIndex: '99',
    background: 'white',
    lineHeight: '12px',
    fontSize: '12px',
    fontWeight: '700',
    top: '6px',
    left: '12px',
    padding: '0 4px',
  },
}));

function SwitchBilling() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const classes = useStyles();
  const { setBilling, business, billing, context } = usePlatform();

  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);

  const billingList = useMemo(() => {
    return business?.data_connectors
      .filter((item) => checkProviderKind(item?.kind, context.provider))
      .filter((item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase())
      );
  }, [business, search, context]);

  const handleChangeBilling = (billingId) => () => {
    if (billing.id === billingId) {
      return;
    }

    const billingSelected = business.data_connectors.find(
      (item) => item.id === billingId
    );
    setBilling(billingSelected);

    history.push('/lighthouse/workspaces');
    localStorage.removeItem('organization-store');
    queryClient.invalidateQueries();
    setVisible(false);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleToggleBusinessList = (visible) => {
    setVisible(visible && billingList?.length > 1);
  };

  function checkProviderKind(kind, provider) {
    if (kind === `${provider}-billing`) {
      return true;
    }

    return provider === 'focus' && kind === 'focus';
  }

  return (
    <Popover
      open={visible}
      placement="bottomRight"
      trigger="click"
      onOpenChange={handleToggleBusinessList}
      content={
        <div style={{ width: 280, maxWidth: 280 }}>
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <Input
              autoFocus
              allowClear
              value={search}
              placeholder={t('common.search.billing')}
              onChange={handleChangeSearch}
              prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,0.3)' }} />}
              style={{ borderRadius: '16px' }}
            />
          </Space>

          <div style={{ margin: '8px -16px -12px -16px' }}>
            <Divider style={{ margin: 0 }} />
            <List
              size="small"
              dataSource={billingList}
              style={{
                overflow: 'hidden auto',
                maxHeight: '250px',
              }}
              renderItem={({ id, name }) => (
                <List.Item
                  onClick={handleChangeBilling(id)}
                  className={clsx(classes.listItem, {
                    selected: billing.id === id,
                  })}
                >
                  <Space
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Typography.Text
                      ellipsis={{ tooltip: name }}
                      style={{ maxWidth: 220 }}
                    >
                      {name}
                    </Typography.Text>
                  </Space>
                </List.Item>
              )}
            />
          </div>
        </div>
      }
    >
      {billingList?.length > 0 && (
        <div style={{ position: 'relative' }}>
          <label className={classes.label}>Billing</label>
          <Button className={classes.button}>
            <Space>
              {billing.name}
              {billingList?.length > 1 && (
                <CaretDownOutlined rotate={visible ? 180 : 0} />
              )}
            </Space>
          </Button>
        </div>
      )}
    </Popover>
  );
}

export default SwitchBilling;
