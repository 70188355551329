import { lazy } from 'react';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

const routes = [
  {
    path: '/lighthouse/anomalies/business/global-settings',
    exact: true,
    title: 'menu.sidebar.anomalies.business',
    component: lazy(() => import('./anomaliesGlobalSettings')),
  },
  {
    path: '/lighthouse/anomalies/business',
    exact: true,
    title: 'menu.sidebar.anomalies.business',
    component: lazy(() => import('./home')),
  },
  {
    path: '/lighthouse/anomalies/business/:id',
    exact: true,
    title: 'menu.sidebar.anomalies.business',
    component: lazy(() => import('./anomaliesGeneralReport')),
  },
  {
    path: '/lighthouse/anomalies/business/:id/report',
    exact: true,
    title: 'menu.sidebar.anomalies.business',
    component: lazy(() => import('./anomaliesReports')),
  },
  {
    path: '/lighthouse/anomalies/business/:id/report/:reportId',
    exact: true,
    title: 'menu.sidebar.anomalies.business',
    component: lazy(() => import('./anomaliesReportShow')),
  },
  {
    path: '/lighthouse/anomalies/business/settings/:id',
    exact: true,
    title: 'menu.sidebar.anomalies.business',
    component: lazy(() => import('./businessAnomaliesSettings')),
  },
];

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      {routes.map(({ title, ...rest }) => (
        <PrivateRoute key={rest.path} title={t(title)} {...rest} />
      ))}
    </Switch>
  );
}

export default Routes;
