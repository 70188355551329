import { useAuth } from '@/context/AuthContext';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

function UserAvatar({ size = 40, ...rest }) {
  const { user } = useAuth();

  return (
    <Avatar
      size={size}
      icon={<UserOutlined />}
      src={user?.photoURL}
      alt="User photo"
      {...rest}
    />
  );
}

export default UserAvatar;
