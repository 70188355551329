export const upperFirst = (value) =>
  String(value)?.[0].toUpperCase() + String(value)?.slice(1);

export const toLower = (value) => String(value)?.toLowerCase();

export const toUpper = (value) => String(value)?.toUpperCase();

export const sortAscName = (a, b) => (a.name <= b.name ? -1 : 1);

export const normalize = (value) =>
  String(value)
    ?.trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\s|-]/g, '_')
    .replace(/[^a-z0-9_.]/g, '');

export const normalize2 = (value) => toLower(value)?.replace(/[\s]/g, '.');
