import { lazy } from 'react';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

const routes = [
  {
    path: '/lighthouse/anomalies/infrastructure',
    exact: true,
    title: 'menu.sidebar.anomalies.infrastructure',
    component: lazy(() => import('./home')),
    providers: ['aws', 'azure', 'gcp'],
  },
  {
    path: '/lighthouse/anomalies/infrastructure/report',
    exact: true,
    title: 'menu.sidebar.anomalies.infrastructure',
    component: lazy(() => import('./anomaliesReport')),
    providers: ['aws', 'azure', 'gcp'],
  },
  {
    path: '/lighthouse/anomalies/infrastructure/report/:id',
    exact: true,
    title: 'menu.sidebar.anomalies.infrastructure',
    component: lazy(() => import('./anomaliesReportShow')),
    providers: ['aws', 'azure', 'gcp'],
  },
  {
    path: '/lighthouse/anomalies/infrastructure/settings',
    exact: true,
    title: 'menu.sidebar.anomalies.infrastructure',
    component: lazy(() => import('./anomaliesGlobalSettings')),
    providers: ['aws', 'azure', 'gcp'],
  },
  {
    path: '/lighthouse/anomalies/infrastructure/settings/data-connectors/:dataConnectorId',
    exact: true,
    title: 'menu.sidebar.anomalies.infrastructure',
    component: lazy(() => import('./anomaliesDataConnectorsSettings')),
    providers: ['aws', 'azure', 'gcp'],
  },
];

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      {routes.map(({ title, ...rest }) => (
        <PrivateRoute key={rest.path} title={t(title)} {...rest} />
      ))}
    </Switch>
  );
}

export default Routes;
