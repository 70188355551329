import React, { useMemo } from 'react';
import vm from '@/assets/images/azure/services/virtualmachines.svg';
import redis from '@/assets/images/azure/services/redis.svg';
import disk from '@/assets/images/azure/services/disk.svg';
import sqldatabase from '@/assets/images/azure/services/sqldatabase.svg';

function AzureImgServices({ kind, size = 32, ...rest }) {
  const image = useMemo(() => {
    const switcher = {
      vm: vm,
      redis: redis,
      disk: disk,
      sql: sqldatabase,

    };
    return switcher[kind];
  }, [kind]);

  return <img src={image} alt={`Logo`} width={size} {...rest} />;
}

export default AzureImgServices;
