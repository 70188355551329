import { useState } from 'react';
import { usePlatform } from '@/context/PlatformContext';
import { createUseStyles } from 'react-jss';
import { Space, Layout, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import SwitchBusiness from './components/SwitchBusiness';
import SwitchContext from './components/SwitchContext';
import SwitchBilling from './components/SwitchBilling';
import { useHistory } from 'react-router-dom';
import logo from '@/assets/images/logo.png';
import CreateIssueModal from '@/components/CreateIssueModal';
import FlushCacheModal from './modals/FlushCacheModal';
import UserMenu from './components/UserMenu';

const useStyles = createUseStyles(() => ({
  header: {
    width: '100%',
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
    zIndex: 1000,
    padding: '0 32px',
    backgroundColor: 'white',
  },
  business: {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.85)',
  },
}));

function Header({
  preventRedirect = false,
  showSelectOptions = true,
  showUserOptionsMenu = true,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { business, org } = usePlatform();
  const [showModalIssue, setShowModalIssue] = useState(false);

  const handleOpenReport = () => {
    setShowModalIssue(true);
  };

  const handleCloseReport = () => {
    setShowModalIssue(false);
  };

  function handleGoToWorkspaces() {
    const message = t('common.has.unsaved.changes');

    if (preventRedirect) {
      if (!window.confirm(message)) {
        return;
      }
    }

    history.push('/lighthouse/workspaces');
  }

  return (
    <Layout.Header className={classes.header}>
      <Row justify="space-between" align="middle">
        <Col>
          <img
            src={logo}
            alt="Logo Pier Cloud"
            width={145}
            onClick={handleGoToWorkspaces}
          />
        </Col>

        <Col>
          <Space size={16}>
            {showSelectOptions && (
              <Space align="center">
                <SwitchBilling />
                <SwitchContext />

                {org?.businesses.length > 0 ? (
                  <SwitchBusiness />
                ) : (
                  <span className={classes.business}>{business?.name}</span>
                )}
              </Space>
            )}

            <UserMenu
              preventRedirect={preventRedirect}
              OnOpenReport={handleOpenReport}
              showUserOptionsMenu={showUserOptionsMenu}
            />
          </Space>
        </Col>
      </Row>

      <CreateIssueModal visible={showModalIssue} onClose={handleCloseReport} />
      <FlushCacheModal />
    </Layout.Header>
  );
}

export default Header;
