import { lazy, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';

function Routes() {
  const { t } = useTranslation();

  const routes = useMemo(
    () => [
      {
        path: ['/lighthouse/drafts/new', '/lighthouse/drafts/:view'],
        exact: true,
        title: 'BI',
        component: lazy(() => import('../workspaces/bi')),
        renderLayout: false,
      },
      {
        path: '/lighthouse/drafts',
        title: t('menu.sidebar.drafts'),
        component: lazy(() => import('./home')),
      },
    ],
    [t]
  );

  return (
    <Switch>
      {routes.map((route) => (
        <PrivateRoute key={route.path} {...route} />
      ))}
    </Switch>
  );
}

export default Routes;
