import { message } from 'antd';
import api from '@/services/lighthouse';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { usePlatform } from '@/context/PlatformContext';

function useMutationDeleteBudgetSettings() {
  const { t } = useTranslation();
  const { billing } = usePlatform();

  return useMutation(
    (id) => api.delete(`/data_connectors/${billing.id}/budget/settings/${id}`),
    {
      onMutate() {
        message.loading({
          content: t('message.deleting'),
          key: 'updatable',
        });
      },
      onSuccess() {
        message.success({
          content: t('message.deleting.success'),
          key: 'updatable',
          duration: 4,
        });
      },
      onError() {
        message.error({
          content: t('message.deleting.error'),
          key: 'updatable',
          duration: 4,
        });
      },
    }
  );
}

export default useMutationDeleteBudgetSettings;
