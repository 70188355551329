import { lazy, useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';

function Routes() {
  const { t } = useTranslation();

  const routes = useMemo(
    () => [
      {
        path: '/errors/error-403',
        exact: true,
        title: t('router.access.not.authorized'),
        component: lazy(() => import('./Error403')),
        configured: false,
      },
    ],
    [t]
  );

  return (
    <Switch>
      {routes.map((route) => (
        <PrivateRoute key={route.path} {...route} />
      ))}
    </Switch>
  );
}

export default Routes;
