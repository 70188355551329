import { useMutation, useQueryClient } from 'react-query';
import { message } from 'antd';
import { apiWithoutBaseUrl } from '@/services/lighthouse';
import { useTranslation } from 'react-i18next';

export default function useMutationDeleteFeatureFlag() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation(
    ({ id }) => apiWithoutBaseUrl.delete(`/feature_flags/${id}`),
    {
      onMutate() {
        message.loading({
          content: t('field.message.deleting'),
          key: 'feature-flag-delete',
        });
      },
      onSuccess() {
        queryClient.invalidateQueries(['feature-flags']);
        message.success({
          content: t('field.message.deleting.success'),
          key: 'feature-flag-delete',
          duration: 4,
        });
      },
      onError(error) {
        message.error({
          content: t('field.message.deleting.error'),
          key: 'feature-flag-delete',
          duration: 4,
        });
      },
    }
  );
}
