import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Overview = lazy(() => import('./overview'));
const Checkers = lazy(() => import('./checkers'));
const Issues = lazy(() => import('./issues'));
const Settings = lazy(() => import('./checkerSettings'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/cca/data-connectors/overview`}
        title="CCA Overview"
        component={Overview}
      />
      <PrivateRoute
        exact
        path={`/cca/data-connectors/checkers`}
        title="CCA Checkers"
        component={Checkers}
      />
      <PrivateRoute
        exact
        path={`/cca/data-connectors/checkers/:checker/issues`}
        title="CCA Issues"
        component={Issues}
      />
      <PrivateRoute
        exact
        path={`/cca/data-connectors/:data_connector_id/checkers/:checker_id/settings`}
        title="CCA Checker Settings"
        component={Settings}
      />
    </>
  );
}

export default Routes;
