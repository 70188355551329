import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./pages/home'));
const Exchange = lazy(() => import('./pages/dataConnectorExchange'));

function Routes() {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`/settings/billing-connectors`}
        title="Billing Connectors"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/settings/billing-connectors/:billingConnectorId/exchange`}
        title="Exchange"
        component={Exchange}
      />
    </Switch>
  );
}

export default Routes;
