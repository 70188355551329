import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Home = lazy(() => import('./home'));
const Create = lazy(() => import('./create'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/settings/users`}
        title="Users"
        component={Home}
      />
      <PrivateRoute
        exact
        path={`/settings/users/create`}
        title="Create User"
        component={Create}
      />
      <PrivateRoute
        exact
        path={`/settings/users/:id/edit`}
        title="Edit User"
        component={Create}
      />
    </>
  );
}

export default Routes;
