import React from 'react';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';

function Message() {
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Result
      status="500"
      title={t('error.crash.title')}
      subTitle={t('error.crash.description')}
      style={{ background: 'transparent' }}
      extra={
        <Button type="primary" onClick={handleReload}>
          {t('common.retry.message')}
        </Button>
      }
    />
  );
}

export default Message;
