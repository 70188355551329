import React from 'react';
import Routes from './Routes';
import { useAuth } from './context/AuthContext';
import { LoadingSpinner } from '@/components';
import { ConfigProvider } from 'antd';
import Modals from './modals';
import ptBR from 'antd/lib/locale/pt_BR';
import esES from 'antd/lib/locale/es_ES';
import enUS from 'antd/lib/locale/en_US';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import tracking from '@/services/tracking';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/es';
import 'dayjs/locale/en-gb';

dayjs.extend(localeData);
dayjs.extend(duration);
dayjs().localeData();

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

// eslint-disable-next-line no-extend-native
String.prototype.capitalizeAll = function () {
  return this.split(' ')
    .map((word) => word.capitalize())
    .join(' ');
};

// eslint-disable-next-line no-extend-native
String.prototype.prefix = function (prefix) {
  return `${prefix}${this}`;
};

// eslint-disable-next-line no-extend-native
String.prototype.suffix = function (suffix) {
  return `${this}${suffix}`;
};

function App() {
  const { loading } = useAuth();
  const { i18n } = useTranslation();

  const languages = {
    'pt-BR': ptBR,
    'es-ES': esES,
    'en-US': enUS,
  };

  const dateLanguages = {
    'pt-BR': 'pt-br',
    'es-ES': 'es',
    'en-US': 'en-gb',
  };

  moment.locale(dateLanguages[i18n.resolvedLanguage]);
  dayjs.locale(dateLanguages[i18n.resolvedLanguage]);

  tracking.init();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ConfigProvider locale={languages[i18n.resolvedLanguage]}>
      <Modals />
      <Routes />
    </ConfigProvider>
  );
}

export default App;
