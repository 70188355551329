import { lazy } from 'react';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import { getAllProvidersKeys } from '@/common/cloudProviders';

const allProviders = getAllProvidersKeys();

const routes = [
  {
    path: '/lighthouse/alarms/import',
    exact: true,
    title: 'router.alarms.import.title',
    component: lazy(() => import('./pages/import')),
    providers: allProviders,
  },
  {
    path: '/lighthouse/alarms',
    exact: true,
    title: 'router.alarms.title',
    component: lazy(() => import('./pages/home')),
    providers: allProviders,
  },
];

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      {routes.map(({ title, ...rest }) => (
        <PrivateRoute key={rest.path} title={t(title)} {...rest} />
      ))}
    </Switch>
  );
}

export default Routes;
