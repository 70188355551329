import createAPI from '@/common/createAPI';
import responseErrorHandler from './helpers/responseErrorHandler';

const api = createAPI(process.env.REACT_APP_LIGHTHOUSE_URL);

api.interceptors.request.use((config) => {
  const org = JSON.parse(localStorage.getItem('org'));
  const business = JSON.parse(localStorage.getItem('business'));

  config.baseURL += `/orgs/${org?.id}/businesses/${business?.id}`;

  const auth = JSON.parse(localStorage.getItem('auth'));
  const context = JSON.parse(localStorage.getItem('context'));

  if (auth) {
    const { access_token: accessToken } = auth;
    config.headers['authorization'] = `Bearer ${accessToken}`;
  }

  if (!config.headers['context-id']) {
    config.headers['context-id'] = context?.id;
  }

  if (!config.headers['context-provider']) {
    config.headers['context-provider'] = context?.provider;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => responseErrorHandler(error)
);

const apiWithoutBaseUrl = createAPI(process.env.REACT_APP_LIGHTHOUSE_URL);

apiWithoutBaseUrl.interceptors.request.use((config) => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  const context = JSON.parse(localStorage.getItem('context'));

  if (auth) {
    const { access_token: accessToken } = auth;
    config.headers['authorization'] = `Bearer ${accessToken}`;
  }

  if (!config.headers['context-id']) {
    config.headers['context-id'] = context?.id;
  }
  config.headers['context-provider'] = context?.provider;

  return config;
});

apiWithoutBaseUrl.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => responseErrorHandler(error)
);

export default api;
export { apiWithoutBaseUrl };
