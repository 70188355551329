import { lazy } from 'react';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

const routes = [
  {
    path: [
      '/lighthouse/data-centers/:id/resources/:resource_id/duplicate',
      '/lighthouse/data-centers/:id/resources/:resource_id',
      '/lighthouse/data-centers/:id/resources/new',
    ],
    exact: true,
    title: 'menu.sidebar.on.premise.costs',
    component: lazy(() => import('./pages/upsertResource')),
    providers: ['onpremise'],
  },
  {
    path: '/lighthouse/data-centers/:id/resources',
    exact: true,
    title: 'menu.sidebar.on.premise.costs',
    component: lazy(() => import('./pages/resources')),
    providers: ['onpremise'],
  },
  {
    path: '/lighthouse/data-centers/settings',
    exact: true,
    title: 'menu.sidebar.on.premise.costs',
    component: lazy(() => import('./pages/settings')),
    providers: ['onpremise'],
  },
  {
    path: '/lighthouse/data-centers',
    exact: true,
    title: 'menu.sidebar.on.premise.costs',
    component: lazy(() => import('./pages/home')),
    providers: ['onpremise'],
  },
  {
    path: '/lighthouse/data-centers/:id/import',
    exact: true,
    title: 'menu.sidebar.on.premise.costs',
    component: lazy(() => import('./pages/import')),
    providers: ['onpremise'],
  },
];

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      {routes.map(({ title, ...rest }) => (
        <PrivateRoute key={rest.path} title={t(title)} {...rest} />
      ))}
    </Switch>
  );
}

export default Routes;
