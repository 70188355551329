import { t } from 'i18next';

export const allDataConnectors = () => [
  {
    kind: 'focus',
    label: 'Focus',
    billing: true,
  },
  {
    kind: 'aws-billing',
    label: 'AWS Billing',
    provider: 'aws',
    billing: true,
    processes: [
      'aws-billing-sync',
      'aws-billing-process',
      'billing-split-process',
    ],
  },
  {
    kind: 'gcp-billing',
    label: 'GCP Billing',
    provider: 'gcp',
    billing: true,
    processes: [
      'gcp-billing-sync',
      'gcp-billing-process',
      'billing-split-process',
    ],
  },
  {
    kind: 'oci-billing',
    label: 'OCI Billing',
    provider: 'oci',
    billing: true,
    processes: [
      'oci-billing-sync',
      'oci-billing-process',
      'billing-split-process',
    ],
  },
  {
    kind: 'azure-billing',
    label: 'Azure Billing',
    provider: 'azure',
    billing: true,
    processes: [
      'azure-billing-sync',
      'azure-billing-process',
      'billing-split-process',
    ],
  },
  {
    kind: 'budget',
    label: t('settings:dataconnector.budget.title'),
    includeBillingConnector: true,
    processes: ['consumer-group-process', 'consumer-group-budget'],
  },
  {
    kind: 'forecast',
    label: t('settings:dataconnector.forecast.title'),
    includeBillingConnector: true,
    processes: ['forecast-infrastructure-process'],
  },
  {
    kind: 'forecast-business',
    label: t('settings:dataconnector.forecast.business.title'),
    includeBillingConnector: true,
    processes: ['forecast-business-process'],
  },
  {
    kind: 'anomaly-business',
    label: t('settings:dataconnector.business.anomaly.title'),
    includeBillingConnector: true,
    processes: ['anomaly-business-process'],
  },
  {
    kind: 'anomaly-infrastructure',
    label: t('settings:dataconnector.infrastructure.anomaly.title'),
    includeBillingConnector: true,
    processes: ['anomaly-infrastructure-process'],
  },
  { kind: 'csv-file', label: t('settings:dataconnector.csv.title') },
  {
    kind: 'cca-checkers',
    label: t('settings:dataconnector.cca.checkers.title'),
  },
  {
    kind: 'prometheus',
    label: 'Prometheus',
    advancedChargeback: true,
    processes: ['prometheus-process'],
  },
  {
    kind: 'aws-athena-chargeback',
    label: 'AWS Athena Chargeback',
    provider: 'aws',
  },
  { kind: 'api', label: 'API', processes: ['api-process'] },
  {
    kind: 'cca-rightsizing-aws',
    label: 'CCA Rightsizing AWS',
    provider: 'aws',
  },
  {
    kind: 'cca-smart-instances',
    label: 'CCA Smart Instances',
  },
  {
    kind: 'cca-payment-plans-aws',
    label: 'CCA Payment Plans AWS',
    provider: 'aws',
  },
  {
    kind: 'cca-rightsizing-azure',
    label: 'CCA Rightsizing Azure',
    provider: 'azure',
  },
  {
    kind: 'cast-ai',
    label: 'Cast AI',
    advancedChargeback: true,
    processes: ['api-process'],
  },
  {
    kind: 'redhat',
    label: 'Redhat',
    advancedChargeback: true,
    processes: ['api-process'],
  },
  { kind: 'lia', label: 'LIA' },
  {
    kind: 'datadog',
    label: 'Datadog',
    advancedChargeback: true,
    processes: ['datadog-process'],
  },
  {
    kind: 'newrelic',
    label: 'New Relic',
    advancedChargeback: true,
    processes: ['newrelic-process'],
  },
  {
    kind: 'blob-storage',
    label: 'Blob Storage',
    advancedChargeback: true,
    processes: ['blob-storage-process'],
  },
  {
    kind: 'advanced-chargeback',
    label: 'Advanced Chargeback',
    processes: ['advanced-chargeback-process'],
    hidden: true,
  },
  {
    kind: 'mongodb-atlas',
    label: 'MongoDB Atlas',
    advancedChargeback: true,
    processes: ['mongodb-atlas-process'],
  },
  {
    kind: 'elastic-cloud',
    label: 'Elastic Cloud',
    advancedChargeback: true,
    processes: ['elastic-cloud-process'],
  },
  {
    kind: 'kubecost',
    label: 'Kubecost',
    advancedChargeback: true,
    processes: ['kubecost-process'],
  },
];

export function getAllDataConnector(
  { except, provider, onlyBilling, removeBilling } = {
    except: [],
    provider: null,
    onlyBilling: false,
    removeBilling: false,
  }
) {
  let dataConnectors = allDataConnectors().filter(
    (dataConnector) => !dataConnector.hidden
  );

  if (Array.isArray(except) && except.length) {
    dataConnectors = dataConnectors.filter(
      (dataconnector) => !except.includes(dataconnector)
    );
  }

  if (!!provider) {
    dataConnectors = dataConnectors.filter(
      (dataConnector) => dataConnector.provider === provider
    );
  }

  if (onlyBilling) {
    dataConnectors = dataConnectors.filter(
      (dataConnector) => dataConnector.billing
    );
  }

  if (removeBilling) {
    dataConnectors = dataConnectors.filter(
      (dataConnector) => !dataConnector.billing
    );
  }

  return dataConnectors;
}

export function getAllDataConnectorKinds(options) {
  return getAllDataConnector(options).map(
    (dataconnector) => dataconnector.kind
  );
}

export function getDataConnector(kind) {
  const dataConnector = allDataConnectors().find(
    (dataConnector) => dataConnector.kind === kind
  );

  if (!dataConnector) {
    console.error(`DataConnector kind not found: ${kind}`);
    return {};
  }

  return dataConnector;
}
