import { useQuery } from 'react-query';
import api from '@/services/lighthouse';
import { usePlatform } from '@/context/PlatformContext';

export default function useFetchContexts({
  page = 1,
  search = '',
  enabled = true,
} = {}) {
  const { business } = usePlatform();
  return useQuery(
    ['contexts', search, page, business?.id],
    () =>
      api
        .get('/contexts', { params: { search, page } })
        .then((res) => res.data),
    {
      enabled,
      retry: 0,
    }
  );
}
