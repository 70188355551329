import React, { useMemo } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import useMutationCreateFeatureFlag from '@/mutations/useMutationCreateFeatureFlag';
import useMutationUpdateFeatureFlag from '@/mutations/useMutationUpdateFeatureFlag';
import { useModal } from '@/context/ModalContext';
import { useTranslation } from 'react-i18next';

function CreateFeatureFlags({ onClose, featureFlag = {}, edition }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const createFeatureFlag = useMutationCreateFeatureFlag();
  const updateFeatureFlag = useMutationUpdateFeatureFlag();

  const onSuccess = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    const { alias, customers } = values;
    if (edition) {
      updateFeatureFlag.mutate(
        {
          id: featureFlag.id,
          alias,
          customers,
        },
        {
          onSuccess,
        }
      );
    } else {
      createFeatureFlag.mutate(
        {
          alias,
          customers,
        },
        {
          onSuccess,
        }
      );
    }
  };

  const loading = useMemo(() => {
    if (createFeatureFlag.isLoading || updateFeatureFlag.isLoading) {
      return true;
    }

    return false;
  }, [createFeatureFlag, updateFeatureFlag]);

  return (
    <Modal
      visible
      onCancel={onClose}
      title={
        edition
          ? t('featureflag.form.title.edition')
          : t('featureflag.form.title.creation')
      }
      footer={[
        <Button key="close" onClick={onClose}>
          {t('common.cancel')}
        </Button>,
        <Button
          form="create-feature-flags"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={loading}
        >
          {t('common.save')}
        </Button>,
      ]}
    >
      <Form
        id="create-feature-flags"
        form={form}
        layout="vertical"
        name="filter"
        autoComplete="off"
        onFinish={handleSubmit}
        initialValues={{
          alias: featureFlag.alias,
          customers: featureFlag.customers || [],
        }}
      >
        <Form.Item
          name="alias"
          label={t('featureflag.form.key')}
          rules={[
            {
              required: true,
              message: t('featureflag.form.key.empty'),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default function ModalWrapper() {
  const modalName = 'CREATE_FEATURE_FLAG';
  const { getModal, getModalProps, closeModal } = useModal();
  const props = getModalProps(modalName);

  function handleClose() {
    closeModal(modalName);
  }

  if (!getModal(modalName)) {
    return null;
  }

  return <CreateFeatureFlags onClose={handleClose} {...props} />;
}
