import { useMutation } from 'react-query';
import { message } from 'antd';
import api from '@/services/lighthouse';
import { useTranslation } from 'react-i18next';

export default function useMutationCreateIssue() {
  const { t } = useTranslation();

  return useMutation(
    (data) =>
      api.post(`/issues`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    {
      onMutate() {
        message.loading({
          content: t('report.message.problem'),
          key: 'issue-create',
        });
      },
      onSuccess() {
        message.success({
          content: t('report.message.problem.success'),
          key: 'issue-create',
          duration: 4,
        });
      },
      onError(error) {
        message.error({
          content: t('report.message.problem.error'),
          key: 'issue-create',
          duration: 4,
        });
      },
    }
  );
}
