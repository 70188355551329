import {
  DollarOutlined,
  DashboardOutlined,
  SettingOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import returnOnlyVisibleMenuItems from '@/common/onlyVisibleMenuItems';

const items = ({ t, adminRole, provider, featureFlags, collapsed }) => {
  const allItems = [
    {
      key: `sp-manager/overview`,
      label: 'Overview',
      icon: <DashboardOutlined />,
      providers: ['aws'],
    },
    {
      key: 'sp-manager/outlet',
      label: 'Outlet',
      icon: <DollarOutlined />,
      providers: ['aws'],
    },
    {
      key: 'sp-manager/plans',
      label: t('spManager:plans'),
      icon: <AuditOutlined />,
      providers: ['aws'],
    },
    {
      key: 'sp-manager/settings',
      label: 'Settings',
      icon: <SettingOutlined />,
      providers: ['aws'],
    },
  ];

  return returnOnlyVisibleMenuItems({
    items: allItems,
    adminRole,
    provider,
  });
};

export default items;
