import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from '@/components';
import { useTranslation } from 'react-i18next';
import OrganizationalMapRoutes from './organizationalMap/Routes';
import ReceiversRoutes from './receivers/Routes';
import ProvisionersRoutes from './provisioners/Routes';

const routes = [
  {
    path: '/lighthouse/budget/checking_accounts/:checkingAccountId',
    exact: true,
    title: 'router.checking.accounts.title',
    component: lazy(() => import('./checkingAccounts')),
  },
];

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      {routes.map(({ title, ...rest }) => (
        <PrivateRoute key={rest.path} title={t(title)} {...rest} />
      ))}

      <Route path="/lighthouse/budget/organizational-map*">
        <OrganizationalMapRoutes />
      </Route>

      <Route path="/lighthouse/budget/receivers*">
        <ReceiversRoutes />
      </Route>

      <Route path="/lighthouse/budget/provisioners*">
        <ProvisionersRoutes />
      </Route>
    </Switch>
  );
}

export default Routes;
