import api from '@/services/lighthouse';
import { useQuery } from 'react-query';
import { usePlatform } from '@/context/PlatformContext';

export default function useFetchFields({
  dataTypes,
  dataConnectorsKinds,
  removeCustoms,
  removeBoosts,
  showOnlyTags,
  contextId,
  contextProvider,
  workspaceId,
  datasetId,
  sorted,
  dataConnectorId,
  enabled = true,
}) {
  const { business } = usePlatform();
  return useQuery(
    [
      'fields',
      dataTypes,
      dataConnectorsKinds,
      removeCustoms,
      removeBoosts,
      showOnlyTags,
      contextId,
      contextProvider,
      datasetId,
      workspaceId,
      sorted,
      dataConnectorId,
      business.id,
    ],
    () =>
      api
        .get('/fields', {
          params: {
            dataTypes,
            dataConnectorsKinds,
            removeCustoms,
            removeBoosts,
            showOnlyTags,
            contextId,
            datasetId,
            workspaceId,
            dataConnectorId,
            sorted,
            contextProvider,
          },
        })
        .then((res) => res.data),
    {
      enabled,
      retry: 0,
    }
  );
}
