import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';

const Ec2 = lazy(() => import('./rightsizingEc2/home'));
const Ec2Show = lazy(() => import('./rightsizingEc2/show'));
const Rds = lazy(() => import('./rightsizingRds/home'));
const RdsShow = lazy(() => import('./rightsizingRds/show'));
const Redis = lazy(() => import('./rightsizingRedis/home'));
const Vms = lazy(() => import('./rightsizingVms/home'));
const Databases = lazy(() => import('./rightsizingDatabases/home'));
const Disk = lazy(() => import('./rightsizingDisk/home'));
const ElastiCache = lazy(() => import('./rightsizingElastiCache/home'));
const ElastiCacheShow = lazy(() => import('./rightsizingElastiCache/show'));
const DatabasesSql = lazy(() => import('./rightsizingDatabaseSql/home'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/ec2`}
        title="Rightsizing EC2"
        component={Ec2}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/ec2/:id`}
        title="Rightsizing EC2"
        component={Ec2Show}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/rds`}
        title="Rightsizing RDS"
        component={Rds}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/rds/:id`}
        title="Rightsizing RDS"
        component={RdsShow}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/redis`}
        title="Rightsizing Redis"
        component={Redis}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/vms`}
        title="Rightsizing Vms"
        component={Vms}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/databases/mysql_postgresql*`}
        title="Rightsizing Databases MySQL/PostgreSQL"
        component={Databases}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/disk`}
        title="Rightsizing Disk"
        component={Disk}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/elasticache`}
        title="Rightsizing ElastiCache"
        component={ElastiCache}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/elasticache/:id`}
        title="Rightsizing ElastiCache"
        component={ElastiCacheShow}
      />
      <PrivateRoute
        exact
        path={`/cca/insights/rightsizing/databases/sql_server*`}
        title="Rightsizing Databases SQL Server"
        component={DatabasesSql}
      />

    </>
  );
}

export default Routes;
